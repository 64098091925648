import React,{useState,useEffect} from 'react'
import { Paper, TextField, Button, Grid,Alert,CircularProgress  } from '@mui/material'
import {AiOutlineUserAdd,AiOutlineUserSwitch} from 'react-icons/ai'
import {GrPowerReset} from 'react-icons/gr'
import * as Api from '../../apis/User'

import './Users.scss'

function UsersForm(props) {
  const paperStyle={padding :25,width:'80vw', margin:"20px auto"}
  const [errors,setErrors] = useState({username:false,email:false})
  const [alertMsg, setAlertMsg] = useState({mag:'',flag:''})
//   const [userType,setUserType] = useState('inwards')
  const [input,setInput] = useState({username:'',email:''})
  const [resetPassloader,setResetPassLoader] = useState(false)
  const [addUpdateLoader,setAddUpdateLoader] = useState(false)

    useEffect(() => {
    if(props.mode === 'update' && props.id){
        Api.getUserInfoById(props.id).then((res) => {
            let {username,email} = res.data?.data
            // setUserType(userType)
            setInput({username,email})
        })
        .catch(err => console.log("error", err.response))
    }
  },[props.id,props.mode])

  const resetPassword = () => {
    setResetPassLoader(true)
    Api.resetLoggedInPassword(props.id)
    .then((res) => {
        setAlertMsg({msg:res?.data?.msg,flag:'success'})
        setTimeout(() => { setAlertMsg({msg:'',flag:''}) }, 5000)
    })
    .catch((err) => {
        setAlertMsg({msg:err?.response?.data?.msg,flag:'error'})
        setTimeout(() => { setAlertMsg({msg:'',flag:''}) }, 5000)
    })
    .finally(() => setResetPassLoader(false))
  }

  const handleInput = (e) => {
    if(e.target.name === 'email'){
        if(e.target.value < 0){
            setErrors({...errors,email:"Email can't be less than 0"})
            return
        }
    }
    setInput({...input,[e.target.name]:e.target.value})
    setErrors({...errors,[e.target.name]:''})
  }
  const handleSubmit = () => {
    setAddUpdateLoader(true)
    let temp_errs = {...errors}

    for(let field in input){
        if(!input[field]){
            temp_errs[field] = field + ' cannot be empty'
        }
    }
    setErrors(temp_errs)
    if(temp_errs.username || temp_errs.email ){
        window.scroll(0,0,'smooth')
        setAlertMsg({msg:"Resolve all the Conflicts First",flag:'error'})
        setTimeout(() => { setAlertMsg({msg:'',flag:''}) }, 5000)
        setAddUpdateLoader(false)
        return
    }

    if(props.mode === 'update'){
        let data = {
            id:props.id,
            // userType:userType,
            ...input
        }
        Api.updateUser(data)
        .then(() => {
            setAlertMsg({msg:"Username Updated",flag:'success'})
            setTimeout(() => { setAlertMsg({msg:'',flag:''}) }, 5000)
        })
        .then(() => props.setReloadData(prev => !prev))
        .catch((err) => {
            console.log(err)
            setAlertMsg({msg:err?.response?.data?.msg??"Server Error Occured, Please Refresh and Try Again...",flag:'error'})
            setTimeout(() => { setAlertMsg({msg:'',flag:''}) }, 5000)
        })
        .finally(() => setAddUpdateLoader(false))
    }
    if(props.mode === 'add'){
        let data = {
            // userType:userType,
            ...input
        }
        Api.addUser(data)
        .then((res) => {
            setAlertMsg({msg:res?.data?.msg,flag:'success'})
            setTimeout(() => { setAlertMsg({msg:'',flag:''}) }, 3000)
            setInput({username:'',email:''})
        })
        .then(() => props.setReloadData(prev => !prev))
        .catch((err) => {
            setAlertMsg({msg:err?.response?.data?.msg,flag:'error'})
            setTimeout(() => { setAlertMsg({msg:'',flag:''}) }, 5000)
        })
        .finally(() => setAddUpdateLoader(false))
    }
  }

  return (
    <Paper elevation={props.mode==='add'?2:0} style={paperStyle} className='UserForm'>
            {alertMsg.flag?
                <Alert severity={alertMsg.flag} className='mb-3 mt-1'>{alertMsg.msg}</Alert>
            :null}
        <Grid container rowSpacing={4}>
            <Grid item container xs={12} sm={12} spacing={3}>
                <Grid item xs = {12} sm={4}>  
                    <TextField value={input.username} onChange={handleInput} error={errors.username} helperText={errors.username} name='username' label='Username' placeholder='Enter Username' variant="outlined" fullWidth required/>
                </Grid>
                <Grid item xs = {12} sm={8}>
                    <TextField value={input.email} onChange={handleInput} error={errors.email} helperText={errors.email} min={0} name='email' label='Email' placeholder='Enter Email' variant="outlined" type='email' fullWidth required/>
                </Grid>
            </Grid>
            <Grid item container sm={12} xs={12} columnSpacing={5}>
                <Grid item xs = {12} sm={6} className='d-flex justify-content-end'>
                    {props.mode === 'update' &&
                        <Button variant = 'contained' color='info' onClick={resetPassword} className={props.mode + "-btn"} >
                            RESET PASSWORD &nbsp;{!resetPassloader?<GrPowerReset size={20}  />:<CircularProgress style={{color:"white"}} size={20}  />}
                        </Button>
                    }
                </Grid>
                <Grid item xs = {12} sm={6} >
                    <Button variant = 'contained' className={props.mode + "-btn"} onClick={handleSubmit}>
                        {props.mode === 'add'?
                        <>ADD USER&nbsp;{!addUpdateLoader?<AiOutlineUserAdd size={20} />:<CircularProgress style={{color:"white"}} size={20} />}</>
                        :
                        <>UPDATE USER &nbsp;{!addUpdateLoader?<AiOutlineUserSwitch size={20}  />:<CircularProgress style={{color:"white"}} size={20}  />}</>
                        }
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Paper>
  )
}

export default UsersForm