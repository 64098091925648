import React, { useState } from "react";
import ProductList from "../ProductList";
import PageHeader from "../../Common/PageHeader/PageHeader";
import * as Api from "../../../apis/Orders";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";

import "./Orders.scss";
import columns from "./OrderColumns";

function Orders() {
  const [orderStatus, setOrderStatus] = useState("all");

  const selectButton = (
    <FormControl variant="standard" className="order-status-select">
      <InputLabel id="orderStatus">Order Status</InputLabel>
      <Select
        value={orderStatus}
        onChange={(e) => setOrderStatus(e.target.value)}
        label="Order Status"
        labelId="orderStatus"
      >
        <MenuItem value={"all"}>All Orders</MenuItem>
        <MenuItem value={1}>Completed Products</MenuItem>
        <MenuItem value={0}>Pending Products</MenuItem>
      </Select>
    </FormControl>
  );

  const addButton = (
    <>
      <Link to="/products-form/orders" style={{ textDecoration: "none" }}>
        <Button variant="contained" color="info">
          Add Order &nbsp; <AiOutlineAppstoreAdd size={23} />
        </Button>
      </Link>
    </>
  );

  const rowClassName = (params, isRowSelected) => {
    if (isRowSelected && params.row.orderStatus === true) {
      return "completed-selected";
    }
    if (params.row.orderStatus === true) {
      return "completed";
    }
  };

  return (
    <div className="Orders">
      <PageHeader heading="Orders" />
      {/* <div className='d-flex justify-content-end mb-2 pe-4'>
              {addButton}
            </div> */}
      <ProductList
        columns={columns}
        addButton={addButton}
        selectButton={selectButton}
        getParams={{ orderStatus: orderStatus }}
        getAllSelectedIds={Api.getAllSelectedOrders}
        getRowData={Api.getOrders}
        rowLink={"/products-form"}
        rowLinkParams={"orders"}
        deleteRowData={Api.deleteOrders}
        dateFilter={true}
        rowClassName={rowClassName}
        activateOrders={Api.activateOrders}
        deactivateOrders={Api.deactivateOrders}
        getOrderReportApi={Api.getOrderReport}
        placeholder="Search by Order ID, Party name or Note"
      />
    </div>
  );
}

export default Orders;
