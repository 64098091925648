import React from 'react'
import { Dialog,IconButton } from '@mui/material'
import ExpensesForm from './UsersForm'
import {AiOutlineClose} from 'react-icons/ai'

function UsersPopup(props) {
  const handleClose = () => {
    props.setOpen(false)
  }
  return (
    <Dialog open={props.open} 
    onBackdropClick={handleClose} 
    onClose={handleClose}
    fullWidth
    maxWidth="100"
    className='UsersPopup'
    PaperProps={{
      sx:{
        width:'100%',
        margin:10
      }
    }}
    >
        <IconButton className='close-icon' onClick={handleClose}><AiOutlineClose size={20} /></IconButton>
        {<ExpensesForm mode='update' setReloadData={props.setReloadData} id={props.userID} />}
    </Dialog>
  )
}

export default UsersPopup