import React, { useState, useMemo, useEffect } from "react";
import ProductList from "../ProductList/ProductList";
import PageHeader from "../Common/PageHeader/PageHeader";
import UsersForm from "./UsersForm";
import UsersPopup from "./UsersPopup";
import * as Api from "../../apis/User";
import { Button } from "@mui/material";
import { AiOutlineEdit } from "react-icons/ai";

import "./Users.scss";
import Spinner from "../Common/Spinner/Spinner";

function Users() {
  const [usersPopup, setUsersPopup] = useState(false);
  const [userID, setUserID] = useState();
  const [reloadData, setReloadData] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  const openUsersPopup = (id) => {
    setUserID(id);
    setUsersPopup(true);
  };

  useEffect(() => {
    Api.getUserInfo().then((res) => {
      setCurrentUser(res?.data?.data);
    });
  }, []);

  const columns = [

    {
      field: "username",
      headerName: "USERNAME",
      width: 300,
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "email",
      headerName: "EMAIL",
      width: 300,
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "edit",
      headerName: "EDIT USER DETAILS",
      flex: 1,
      sortable: false,

      renderCell: (params) => (
        <div className="edit-button-col" onClick={(e) => e.stopPropagation()}>
          {currentUser?.id !== Number(params.id) ? (
            <Button
              variant="contained"
              size="small"
              className="edit-button"
              tabIndex={-1}
              onClick={() => openUsersPopup(params.id)}
            >
              EDIT <AiOutlineEdit size={20} />
            </Button>
          ) : (
            <em className="mt-auto mb-auto">Cannot Edit Current Logged In User</em>
          )}
        </div>
      ),
    },
  ];

  const PropsRender = useMemo(() => {
    return (
      <ProductList
        columns={columns}
        getRowData={Api.getUsers}
        getAllSelectedIds={Api.getAllSelectedUsers}
        deleteRowData={Api.deleteUsers}
        idsWithoutCheckbox = {[currentUser?.id]}
        // dateFilter = {true}
        // rowClassName = {rowClassName}
        reloadRowData={reloadData}
        initalField="username"
        // addButton={addButton}
      />
    );
  }, [reloadData,currentUser]);

  const UserFormRender = useMemo(() => {
    return <UsersForm mode="add" setReloadData={setReloadData} />;
  }, [reloadData]);

  const UserPopupRender = useMemo(() => {
    return (
      <UsersPopup
        open={usersPopup}
        setOpen={setUsersPopup}
        userID={userID}
        setReloadData={setReloadData}
      />
    );
  }, [reloadData, userID, usersPopup]);

  if(!currentUser){
    return <Spinner />
  }

  return (
    <div className="Users">
      <PageHeader heading="Users" />
      {/* <UsersForm mode="add" setReloadData={setReloadData} /> */}
      {UserFormRender}
      {PropsRender}
      {UserPopupRender}
    </div>
  );
}

export default Users;
