import React from 'react'
import {Card,CardMedia,Divider,CardActions} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import HoverButton from '../Common/HoverButton/HoverButton'

function HomeCard(props){
    const nav = useNavigate()
    return(
        <Card className='home-card' onClick = {() => nav(props.link)}>
            <CardMedia
                title=''
                image={props?.image}
                className={`home-card-media ${props.size??"full"}`}
            />
            <Divider />
            <CardActions className='flex ml-auto p-0 home-card-action' >
                    <HoverButton  >
                    {props?.text.toUpperCase()}
                    </HoverButton>
            </CardActions>

        </Card>
    )
}

export default HomeCard