import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineDelete, AiOutlineFilePdf } from "react-icons/ai";
import { MdChecklistRtl, MdOutlinePendingActions } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../Common/Alert/CustomAlert";
import { DateRangePickerWrapper } from "../Common/DateTime/DateTime";
import Spinner from "../Common/Spinner/Spinner";

import "./ProductList.scss";
import generateOrderReport from "../ProductsForm/GenerateReport/generateOrderReport";

function ProductList(props) {
  const [rows, setRows] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCountState, setRowCountState] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState([10]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);

  const [reloadRowData, setReloadRowData] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ message: "", flag: "" });
  const [sortingQuery, setSortingQuery] = useState({
    sortModel: [
      {
        field: props.initalField ?? "dateTime",
        sort: props.initialSort ?? "desc",
      },
    ],
  });
  const [filterQuery, setFilterQuery] = useState({
    filterModel: { quickFilterValues: [""] },
  });
  const [dateRange, setDateRange] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orderReportLoader, setOrderReportLoader] = useState(false);
  const [tableStats, setTableStats] = useState();

  const nav = useNavigate();

  const renderCustomAlert = useMemo(() => {
    return alertMsg.message ? (
      <CustomAlert
        popup={true}
        flag={alertMsg.flag}
        setAlertMsg={setAlertMsg}
        timeout={alertMsg.timeout}
      >
        {alertMsg.message}
      </CustomAlert>
    ) : null;
  }, [alertMsg]);

  const confirmDelete = () => {
    if(selectionModel.length < 1){
      return false;
    }
    setConfirmDeletePopup(true)
  };

  const closeDeletePopup = () => {
    setConfirmDeletePopup(false);
  }

  const tableStatsRender = useMemo(() => {
    if (!tableStats) {
      return;
    }
    let absNetBalance = Math.round(Math.abs(
      tableStats.outwardsValue - tableStats.inwardsValue
    )*10)/10;
    let netBalanceSign = tableStats.outwardsValue - tableStats.inwardsValue;
    let inwardsColor = tableStats.colorInverse ? "text-success" : "text-danger";
    let outwardsColor = tableStats.colorInverse
      ? "text-danger"
      : "text-success";
    let netBalanceColor = netBalanceSign >= 0 ? outwardsColor : inwardsColor;
    return (
      tableStats && (
        <div className="d-flex justify-content-end fs-5 mb-3">
          {tableStats.inwardsValue >= 0 && (
            <div className={`me-4 mb-1`}>
              <strong>Inwards Account</strong>:{" "}
              <span className={`${inwardsColor}`}>
                {tableStats.inwardsValue}
              </span>
            </div>
          )}
          {tableStats.outwardsValue >= 0 && (
            <div className={`me-4 mb-1`}>
              <strong>Outwards Account:</strong>{" "}
              <span className={`${outwardsColor}`}>
                {tableStats.outwardsValue}
              </span>
            </div>
          )}
          {tableStats.inwardsValue >= 0 && tableStats.outwardsValue >= 0 && (
            <div className={`me-4 mb-1`}>
              <strong>Net Balance:</strong>{" "}
              <span className={`${netBalanceColor}`}>{absNetBalance}</span>
            </div>
          )}
        </div>
      )
    );
  }, [tableStats]);

  const searchBarRender = useMemo(() => {
    return (
      <div className="d-flex justify-content-start right">
        {props.addButton}
        <GridToolbarQuickFilter
          className="search"
          placeholder={
            props.placeholder ?? "Search by Ref Number , Party Name or by Note"
          }
          key="filter"
        />
      </div>
    );
  }, [props.addButton]);

  const CustomToolbar = useCallback(() => {
    return (
      <>
        <Dialog
        open={confirmDeletePopup}
        onClose={closeDeletePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onBackdropClick={closeDeletePopup}
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to delete ${selectionModel.length} row(s) ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <stong>Warning!</stong>
            <ol>
              <li>On deleting an item, all products & orders containing that item will also be deleted.</li>
              <li>On deleting a party, all of its products & orders will also be deleted.</li>
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="justify-content-around">
          <Button style={{color:"red"}} onClick={handleDeleteRows}>DELETE</Button>
          <Button onClick={closeDeletePopup} autoFocus>
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
        {selectionModel.length > 0 && props.rowLinkParams === "orders" ? (
          <div className="mb-3 d-flex justify-content-end status-btns-wrapper">
            <Button onClick={getOrderReport} variant="contained" color="error">
              Order Report{" "}
              {orderReportLoader ? (
                <CircularProgress
                  size={18}
                  className="ms-2"
                  style={{ color: "white" }}
                />
              ) : (
                <AiOutlineFilePdf size={18} className="ms-2" />
              )}
            </Button>
            <Button
              onClick={() => changeOrderStatus(props.activateOrders)}
              variant="contained"
              color="success"
            >
              Completed <MdChecklistRtl size={18} className="ms-2" />
            </Button>
            <Button
              onClick={() => changeOrderStatus(props.deactivateOrders)}
              variant="contained"
              color="warning"
            >
              Pending <MdOutlinePendingActions size={18} className="ms-1" />
            </Button>
          </div>
        ) : null}
        {tableStatsRender}
        <GridToolbarContainer className="mt-2 custom-toolbar">
          <div className="d-flex justify-flex-start flex-column left">
            <div className="d-flex flex-row justify-content-between mb-1 top-row">
              <div className="d-flex flex-row">
                <GridToolbarColumnsButton variant="contained" />
                {props.dateFilter ? (
                  <DateRangePickerWrapper
                    placeholder="Date Range Filter"
                    date={dateRange}
                    changeDate={(newVal) => setDateRange(newVal)}
                  />
                ) : null}
              </div>
              {searchBarRender}
            </div>
            <div className="d-flex flex-row justify-content-between bottom-row">
              {selectionModel?.length > 0 ? (
                <Button
                  onClick={confirmDelete}
                  variant="contained"
                  color="error"
                  className="mt-2"
                >
                  Delete Rows&nbsp; <AiOutlineDelete size={20} />
                </Button>
              ) : (
                <div></div>
              )}
              <div className="mt-3" style={{ width: 300, marginRight: 10 }}>
                {props.selectButton && props.selectButton}
              </div>
            </div>
          </div>

          <br />
        </GridToolbarContainer>
      </>
    );
  }, [selectionModel, dateRange, props.addButton, alertMsg, tableStats,confirmDeletePopup,orderReportLoader]);

  const handleSortModelChange = useCallback((sortModel) => {
    setSortingQuery({ sortModel: [...sortModel] });
  }, []);
  const onFilterChange = useCallback((filterModel) => {
    setLoading(true);
    setFilterQuery({ filterModel: { ...filterModel } });
  }, []);

  const getOrderReport = () => {
    setOrderReportLoader(true);
    props
      .getOrderReportApi(selectionModel)
      .then(async (res) => {
        let responseData = res?.data?.data
        await generateOrderReport(responseData.orders,responseData.fileName)
      })
      .catch((err) => console.log(err))
      .finally(() => setOrderReportLoader(false));
  };

  const changeOrderStatus = (func) => {
    func(selectionModel)
      .then(() => {
        window.scroll(0, 0, "smooth");
        setAlertMsg({
          message: "Order Status Changed Successfully",
          flag: "success",
          timeout: 3000,
        });
        setSelectionModel([]);
        setReloadRowData(!reloadRowData);
      })
      .catch(() =>
        setAlertMsg({
          message:
            "Some Error Occured, Could Not Change the Status. Please Try Again...",
          flag: "error",
          timeout: 4000,
        })
      );
  };
  const handleSelectionModelChange = (newSelectionModel) => {
    if (
      selectionModel.length > pageSize &&
      newSelectionModel.length !== selectionModel.length - 1 &&
      newSelectionModel.length !== selectionModel.length + 1
    ) {
      setSelectionModel([]);
    } else if (
      selectionModel.length !== newSelectionModel.length - 1 &&
      newSelectionModel.length === rows.length &&
      rowCountState > pageSize
    ) {
      props
        .getAllSelectedIds()
        .then((res) => setSelectionModel(res?.data?.data?.ids))
        .catch((err) => setSelectionModel([]));
    } else {
      setSelectionModel(newSelectionModel);
    }
  };

  const handleDeleteRows = () => {
    setConfirmDeletePopup(false);
    setLoading(true);
    props
      .deleteRowData(selectionModel)
      .then(() => {
        setAlertMsg({
          message: "Selected Rows Deleted Successfully",
          flag: "success",
          timeout: 3000,
        });
        setSelectionModel([]);
        setReloadRowData(!reloadRowData);
        setLoading(false);
      })
      .catch(() => {
        setAlertMsg({
          message: "Rows Deleteion Failed, Please Refresh and Try Again...",
          flag: "error",
          timeout: 3000,
        });
        setLoading(false);
      });
  };

  const handleRowClick = (rowData) => {
    if (props.rowLinkParams === "buyProducts") {
      nav(props.rowLink + "/inwards/" + rowData.id);
    } else if (props.rowLinkParams === "sellProducts") {
      nav(props.rowLink + "/outwards/" + rowData.id);
    } else if (props.rowLinkParams === "orders") {
      nav(props.rowLink + "/orders/" + rowData.id);
    }else if (props.rowLinkParams === "partyLists") {
      nav(props.rowLink + "/" + rowData.id);
    } else if (props.rowLinkParams === "partyTransactions") {
      nav(
        props.rowLink + "/" + rowData.row?.transactionType + "/" + rowData.id
      );
    }
  };

  const isRowSelected = (row_id) => {
    return selectionModel.includes(row_id);
  };

  useEffect(() => {
    setLoading(true);
    const params = {
      page,
      pageSize,
      sortField: sortingQuery?.sortModel[0]?.field,
      sortOrder: sortingQuery?.sortModel[0]?.sort,
      filterTerm: filterQuery.filterModel.quickFilterValues[0] ?? "",
      dateRange: dateRange,
      ...props.getParams,
    };
    props
      .getRowData(params)
      .then((res) => {
        let { rows, totalRows, stats } = res.data?.data;
        console.log("🚀 ProductList || .then || rows:", res.data);

        setRowCountState(totalRows);
        setRowsPerPage(
          Array.from(
            { length: Math.min(100, Math.floor(totalRows / 10)) },
            (_, i) => (i + 1) * 10
          )
        );
        if (!tableStats) {
          setTableStats(stats);
        }

        setRows(rows);
      })
      .catch((err) => console.log("error", err.response))
      .finally(() => setLoading(false));
  }, [
    page,
    pageSize,
    sortingQuery,
    filterQuery,
    props.reloadRowData,
    reloadRowData,
    dateRange,
    props.getParams,
  ]);

  if (!rows) {
    return <Spinner />;
  }
  return (
    <div className="ProductList">
      {renderCustomAlert}
      
      <DataGrid
        // General Props
        className={`product-list-grid`}
        loading={loading}
        rows={rows}
        onRowClick={(rowData) => handleRowClick(rowData)}
        columns={props.columns}
        checkboxSelection
        isRowSelectable={(params) =>
          !props?.idsWithoutCheckbox?.includes(params.id)
        }
        disableSelectionOnClick
        disableColumnMenu
        autoHeight
        getRowHeight={() =>
          props.rowLinkParams === "partyLists" ||
          props.rowLinkParams === "itemLists"
            ? 55
            : "auto"
        }
        getEstimatedRowHeight={() => 55}
        getRowId={(row) => row.id}
        getRowClassName={(params) =>
          props.rowClassName
            ? props.rowClassName(params, isRowSelected(params.id))
            : null
        }
        //Pagination
        pagination
        paginationMode="server"
        rowCount={rowCountState}
        pageSize={pageSize}
        rowsPerPageOptions={[...rowsPerPage]}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        //Sorting
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        initialState={{
          sorting: {
            sortModel: sortingQuery.sortModel,
          },
        }}
        //Filter
        filterMode="server"
        disableColumnFilter
        onFilterModelChange={onFilterChange}
        //Selection
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionModelChange}
        keepNonExistentRowsSelected
        //Toolbar
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: LinearProgress,
        }}
      />
    </div>
  );
}

export default ProductList;
