import React from 'react'
import { Dialog,IconButton } from '@mui/material'
import ExpensesForm from './ExpensesForm'
import {AiOutlineClose} from 'react-icons/ai'

function ExpensesPopup(props) {
  const handleClose = () => {
    props.setOpen(false)
  }
  return (
    <Dialog open={props.open} 
    onBackdropClick={handleClose} 
    onClose={handleClose}
    fullWidth
    maxWidth="100"
    className='ExpensesPopup'
    PaperProps={{
      sx:{
        width:'100%',
        margin:10
      }
    }}
    >
        <IconButton className='close-icon' onClick={handleClose}><AiOutlineClose size={20} /></IconButton>
        {<ExpensesForm mode='update' toggleReload={props.toggleReload} id={props.expenseID} />}
    </Dialog>
  )
}

export default ExpensesPopup