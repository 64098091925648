import {axiosInstance} from './Common'

export const getOrders = (params) => {
    return axiosInstance.get('orders',{params})
}



export const activateOrders = (ids) => {
    return axiosInstance.patch('orders',{ids:ids,status:1})
}

export const deactivateOrders = (ids) => {
    return axiosInstance.patch('orders',{ids:ids,status:0})
}

export const deleteOrders = (ids) => {
    return axiosInstance.delete('orders',{data:ids})
}

export const getOrderReport = (ids) => {
    return axiosInstance.get('orders/get-report',{params:{ids:ids}})
}

export const getAllSelectedOrders = () => {
    return axiosInstance.get('orders/all-selected')
}
