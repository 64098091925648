import React,{useState,useEffect, useMemo} from 'react'
import { Paper, TextField, Button, Grid,FormControl,InputLabel,Select,MenuItem,CircularProgress  } from '@mui/material'
import {  DateTimePickerWrapper } from '../../Common/DateTime/DateTime';
import {AiOutlineFileAdd} from 'react-icons/ai'
import {GrDocumentUpdate} from 'react-icons/gr'
import dayjs from 'dayjs'
import * as Api from '../../../apis/Expenses'
import './Expenses.scss'
import CustomAlert from '../../Common/Alert/CustomAlert';


function ExpensesForm(props) {
  const paperStyle={padding :25,width:'80vw', margin:"20px auto"}
  const [errors,setErrors] = useState({details:false,amount:false,dateTime:false})
  const [alertMsg, setAlertMsg] = useState({message:'',flag:''})
  const [dateTime,setDateTime] = useState(new Date())
  const [expenseType,setExpenseType] = useState('inwards')
  const [input,setInput] = useState({details:'',amount:''})
  const [loading,setLoading] = useState(false)

    useEffect(() => {
    if(props.mode === 'update' && props.id){
        Api.getExpenseDetails(props.id).then((res) => {
            let {dateTime,expenseType,details,amount} = res.data?.data
            setDateTime(dateTime)
            setExpenseType(expenseType)
            setInput({details:details,amount:amount})
        })
        .catch(err => console.log("error", err.response))
    }
  },[props.id,props.mode])

  const renderCustomAlert = useMemo(() => {
    return alertMsg.message ? (
      <CustomAlert
        popup={true}
        flag={alertMsg.flag}
        setAlertMsg={setAlertMsg}
        timeout={alertMsg.timeout}
      >
        {alertMsg.message}
      </CustomAlert>
    ) : null;
  }, [alertMsg]);

  const handleInput = (e) => {
    if(e.target.name === 'amount'){
        if(e.target.value < 0){
            setErrors({...errors,amount:"Amount can't be less than 0"})
            return
        }
    }
    setInput({...input,[e.target.name]:e.target.value})
    setErrors({...errors,[e.target.name]:''})
  }

  const changeDate = (newDate) => {
    setDateTime(newDate);
    if(!dayjs(newDate).isValid()){
        setErrors({...errors,dateTime:"Invalid Date Value"});
    }else{
        setErrors({...errors,dateTime:false});
    }
  }
  const handleSubmit = () => {
    if(loading === true){
        return;
    }
    setLoading(true)
    let temp_errs = {...errors}
    if(!dayjs(dateTime).isValid()){
        temp_errs['dateTime'] = "Invalid Date Value"
    }
    for(let field in input){
        if(!input[field]){
            temp_errs[field] = field + ' cannot be empty'
        }
    }
    setErrors(temp_errs)
    if(temp_errs.details || temp_errs.amount || temp_errs.dateTime){
        window.scroll(0,0,'smooth')
        setAlertMsg({message:"Resolve all the Conflicts First",flag:'error'})
        setTimeout(() => { setAlertMsg({message:'',flag:''}) }, 5000)
        setLoading(false)
        return
    }

    if(props.mode === 'update'){
        let data = {
            id:props.id,
            dateTime:dateTime,
            expenseType:expenseType,
            ...input
        }
        Api.updateExpense(data)
        .then(() => {
            setAlertMsg({message:"Expense Details Updated",flag:'success'})
            setTimeout(() => { setAlertMsg({message:'',flag:''}) }, 5000)
        })
        .catch((err) => {
            console.log(err)
            setAlertMsg({message:"Server Error Occured, Please Refresh and Try Again...",flag:'error'})
            setTimeout(() => { setAlertMsg({message:'',flag:''}) }, 5000)
        })
        .finally(() => setLoading(false))
    }
    if(props.mode === 'add'){
        let data = {
            dateTime:dateTime,
            expenseType:expenseType,
            ...input
        }
        Api.addExpense(data)
        .then(() => {
            setAlertMsg({message:"Expense Details Added",flag:'success'})
            setTimeout(() => { setAlertMsg({message:'',flag:''}) }, 3000)
        })
        .catch((err) => {
            setAlertMsg({message:"Server Error Occured, Please Refresh and Try Again...",flag:'error'})
            setTimeout(() => { setAlertMsg({message:'',flag:''}) }, 5000)
        })
        .finally(() => setLoading(false))
    }
    props.toggleReload()

  }

  return (
    <Paper elevation={props.mode==='add'?2:0} style={paperStyle} className='ExpenseForm'>
        {renderCustomAlert}
        <Grid container rowSpacing={4}>
            <Grid item container xs={12} sm={12} spacing={3}>
                <Grid item xs = {12} lg={2} md={3.5}>
                <DateTimePickerWrapper 
                    placeholder="Date Picker" 
                    dateTime = {dateTime} 
                    changeDateTime = {(newVal) => changeDate(newVal)}
                    maxDate={new Date()}
                    errorText={errors.dateTime}
                />
                </Grid>
                <Grid item xs = {12} lg={7.5} md={6}>  
                    <TextField value={input.details} onChange={handleInput} error={errors.details} helperText={errors.details} name='details' label='Details' placeholder='Enter Expense Details' variant="outlined" fullWidth required/>
                </Grid>
                <Grid item xs = {12} sm={2.5}>
                    <TextField value={input.amount} onChange={handleInput} error={errors.amount} helperText={errors.amount} min={0} name='amount' label='Amount' placeholder='Enter Amount' variant="outlined" type='number' fullWidth required/>
                </Grid>
            </Grid>
            <Grid item container sm={12} xs={12} columnSpacing={5}>
                <Grid item xs = {12} sm={6} className='d-flex justify-content-end'>
                    <FormControl variant="standard" className='expense-selector'>
                        <InputLabel id="expenseType">Expense Type</InputLabel>
                        <Select
                            value={expenseType}
                            onChange={(e) => setExpenseType(e.target.value)}
                            label="Expense Type"
                            labelId = "expenseType"
                            >
                            <MenuItem value={"inwards"}>Inwards</MenuItem>
                            <MenuItem value={"outwards"}>Outwards</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs = {12} sm={6} >
                    <Button variant = 'contained' className={props.mode + "-btn"} onClick={handleSubmit}>
                        {props.mode === 'add'?
                        <>ADD EXPENSE&nbsp;{!loading?<AiOutlineFileAdd size={20} />:<CircularProgress size={20} style={{color:"#fff"}} />}</>
                        :
                        <>UPDATE EXPENSE &nbsp;{!loading?<GrDocumentUpdate size={20}  />:<CircularProgress size={20} style={{color:"#fff"}} />}</>
                        }
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Paper>
  )
}

export default ExpensesForm