import React, { useState, useMemo, useEffect } from "react";
import ProductList from "../ProductList";
import PageHeader from "../../Common/PageHeader/PageHeader";
import ExpensesForm from "./ExpensesForm";
import ExpensesPopup from "./ExpensesPopup";
import * as Api from "../../../apis/Expenses";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { AiOutlineEdit } from "react-icons/ai";
import dayjs from "dayjs";

import "./Expenses.scss";

function Expenses() {
  const [expensePopup, setExpensePopup] = useState(false);
  const [expenseID, setExpenseID] = useState();
  const [expenseType, setExpenseType] = useState("all");
  const [reloadData, setReloadData] = useState(0);

  const openExpensePopup = (id) => {
    setExpenseID(id);
    setExpensePopup(true);
  };

  const toggleReload = () => {
    setReloadData(reloadData===1?0:1)
  }

  const addButton = (
    <FormControl variant="standard" className="expense-selector-list">
      <InputLabel id="expenseType">Expense Type</InputLabel>
      <Select
        value={expenseType}
        onChange={(e) => setExpenseType(e.target.value)}
        label="Expense Type"
        labelId="expenseType"
      >
        <MenuItem value={"all"}>ALL</MenuItem>
        <MenuItem value={"inwards"}>Inwards</MenuItem>
        <MenuItem value={"outwards"}>Outwards</MenuItem>
      </Select>
    </FormControl>
  );

  const formatDate = (params) => {
    let tempDate = dayjs(params.value).format("DD/MM/YYYY hh:mm:ss A");
    return tempDate;
  };

  const columns = [
    {
      field: "dateTime",
      headerName: "DATE",
      width: 150,
      sortingOrder: ["asc", "desc"],
      valueFormatter: formatDate,
    },
    {
      field: "details",
      headerName: "DETAILS",
      flex: 1,
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "amount",
      headerName: "AMOUNT",
      width: 150,
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "expenseType",
      headerName: "Expense Type",
      width: 150,
      sortingOrder: ["asc", "desc"],
      valueFormatter: (params) => params.value?.toUpperCase(),
    },
    {
      field: "edit",
      headerName: "EDIT EXPENSES",
      flex: 0.5,
      sortable: false,

      renderCell: (params) => (
        <div className="w-100 h-100 " onClick={(e) => e.stopPropagation()}>
          <Button
            variant="contained"
            size="small"
            className="edit-button"
            tabIndex={-1}
            onClick={() => openExpensePopup(params.id)}
          >
            EDIT <AiOutlineEdit size={20} />
          </Button>
        </div>
      ),
    },
  ];

  const rowClassName = (params, isRowSelected) => {
    if (isRowSelected) {
      return `${params?.row?.expenseType}-selected`;
    }
    if (params.row.expenseType === "outwards") {
      return "outwards";
    }
    if (params.row.expenseType === "inwards") {
      return "inwards";
    }
  };

  const PropsRender = useMemo(() => {
    return (
      <ProductList
        columns={columns}
        getParams={{ expenseType: expenseType }}
        getRowData={Api.getExpenses}
        getAllSelectedIds={Api.getAllSelectedExpenses}
        deleteRowData={Api.deleteExpense}
        dateFilter={true}
        rowClassName={rowClassName}
        reloadRowData={reloadData}
        initalField="dateTime"
        addButton={addButton}
      />
    );
  }, [reloadData, expenseType]);

  const AddExpenseRender = useMemo(() => {
    return <ExpensesForm mode="add" toggleReload={toggleReload} />;
  },[reloadData]);

  const EditExpenseRender = useMemo(() => {
    return(
      <ExpensesPopup
      open={expensePopup}
      setOpen={setExpensePopup}
      expenseID={expenseID}
      toggleReload={toggleReload}
    />
    )
  },[expensePopup,expenseID,reloadData])

  return (
    <div className="Expenses">
      <PageHeader heading="Cash Account" />
      {AddExpenseRender}
      {PropsRender}
      {EditExpenseRender}
    </div>
  );
}

export default Expenses;
