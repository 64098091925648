import React, { useState, useEffect, useMemo } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { TextField, Button } from "@mui/material";
import { AiOutlineUserAdd } from "react-icons/ai";
import { MdSystemUpdateAlt } from "react-icons/md";
import CustomAlert from "../../Common/Alert/CustomAlert";

function ItemPopup(props) {
  const [itemName, setItemName] = useState("");
  const [error, setError] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ msg: "", flag: "" });

  const renderCustomAlert = useMemo(() => {
    return alertMsg.msg ? (
      <CustomAlert
        popup={true}
        flag={alertMsg.flag}
        setAlertMsg={setAlertMsg}
        timeout={alertMsg.timeout}
      >
        {alertMsg.msg}
      </CustomAlert>
    ) : null;
  }, [alertMsg]);

  const addItem = () => {
    if (!itemName) {
      setError("This field cannot be left empty");
      return;
    }
    props
      .addItemApi(itemName)
      .then((res) => {
        setAlertMsg({ msg: "Item Added Successfully", flag: "success" });
        setTimeout(() => {
          setAlertMsg({ msg: "", flag: "" });
        }, [5000]);
        setItemName("");
        props.reloadItems();
      })
      .catch((err) => {
        setAlertMsg({ msg: err.response.data.msg, flag: "error" });
        setTimeout(() => {
          setAlertMsg({ msg: "", flag: "" });
        }, [5000]);
      });
  };

  const updateItem = () => {
    if (!itemName) {
      setError("This field cannot be left empty");
      return;
    }
    props
      .updateItemApi({ itemName: itemName, id: props.editedItem.id })
      .then((res) => {
        setAlertMsg({ msg: "Item Updated Successfully", flag: "success" });
        setTimeout(() => {
          setAlertMsg({ msg: "", flag: "" });
        }, [5000]);
        props.reloadItems();
      })
      .catch((err) => {
        console.log(err);
        setAlertMsg({ msg: err.response.data.msg, flag: "error" });
        setTimeout(() => {
          setAlertMsg({ msg: "", flag: "" });
        }, [5000]);
      });
  };

  const handleClose = () => {
    setError(false);
    props.setOpen(false);
    setAlertMsg({msg:"",flag:""})
  };

  const changeItemName = (newName) => {
    setItemName(newName);
    setError(false);
  };

  useEffect(() => {
    if (props.editedItem?.id) {
      setItemName(props.editedItem.itemName);
    } else {
      setItemName("");
    }
  }, [props.editedItem]);

  return (
    <>
      
      <Dialog
        open={props.open}
        onBackdropClick={handleClose}
        className="ItemPopup"
      >
        {renderCustomAlert}
        <h4 align="center">
          {!props.editedItem?.id ? "ADD ITEM" : "EDIT ITEM"}
        </h4>
        <DialogContent
          sx={{
            padding: 5,
            paddingTop: 2,
          }}
        >
          <DialogContentText>
            Provide item name in the below field
            <br />
            <i>* Item names must be unique</i>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="itemName"
            label="Item Name"
            fullWidth
            variant="standard"
            value={itemName}
            onChange={(e) => changeItemName(e.target.value)}
            helperText={error}
            error={error}
          />
        </DialogContent>
        <DialogActions
          sx={{
            padding: 5,
            paddingTop: 0,
          }}
          className="d-flex justify-content-between"
        >
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
          {!props.editedItem?.id ? (
            <Button variant="contained" color="success" onClick={addItem}>
              <AiOutlineUserAdd size={28} /> Add{" "}
            </Button>
          ) : (
            <Button variant="contained" color="success" onClick={updateItem}>
              <MdSystemUpdateAlt
                style={{ color: "white", marginRight: 10 }}
                size={24}
              />{" "}
              Update{" "}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ItemPopup;
