import React from 'react'
import {Routes,Route} from 'react-router-dom'
import Home from './Home/Home'
import ProductsForm from './ProductsForm/ProductsForm'
import SellProducts from './ProductList/SellProducts/SellProducts'
import BuyProducts from './ProductList/BuyProducts/BuyProducts'
import Orders from './ProductList/Orders/Orders'
import PartyList from './ProductList/PartyList/PartyList'
import ItemList from './ProductList/ItemList/ItemList'
import PartyTransactions from './ProductList/PartyTransactions/PartyTransactions'
import Header from './Header/Header'
import PageNotFound from './ErrorPages/PageNotFound/PageNotFound';
import Login from './Login/Login'
import ForgotPassword from './Login/ForgotPassword/ForgotPassword'
import ResetPassword from './Login/ForgotPassword/ResetPassword'
import Expenses from './ProductList/Expenses/Expenses'

import { PrivateRoute,PublicRoute } from './RouteTypes'
import Users from './Users/Users'


function Main(){
    return(<>
        <Header />
        <Routes>
            <Route path='/login' element={
                <PublicRoute><Login /></PublicRoute>
            } />
            <Route path='/forgot-password' element={
                <PublicRoute><ForgotPassword /></PublicRoute>
            } />
            <Route path='/reset-password/:token' element={
                <ResetPassword />
            } />
            <Route exact path='/' element={
                <PrivateRoute><Home /></PrivateRoute>
            } />
            <Route exact path='/users' element={
                <PrivateRoute><Users /></PrivateRoute>
            } />
            <Route path='/products-form/:transaction_type/:id?' element={
                <PrivateRoute><ProductsForm /></PrivateRoute>
            } />
            {/* <Route path='/sell-products' element={
                <PrivateRoute><SellProducts /></PrivateRoute>
                
            } /> */}
            <Route path='/buy-products' element={
                <PrivateRoute>
                    <BuyProducts />
                </PrivateRoute>
            } />
            <Route path='/sell-products' element={
                <PrivateRoute>
                    <SellProducts />
                </PrivateRoute>
            } />
            <Route path='/orders' element={
                <PrivateRoute>
                    <Orders />
                </PrivateRoute>
            } />
            <Route path='/item-list' element={
                <PrivateRoute>
                    <ItemList />
                </PrivateRoute>
            } />
            <Route path='/party-list' element={
                <PrivateRoute>
                    <PartyList />
                </PrivateRoute>
            } />
            <Route exact path='/party-transactions/:party_id' element={
                <PrivateRoute>
                    <PartyTransactions />           
                </PrivateRoute>
                
            } />
            <Route exact path='/cash-account' element={
                <PrivateRoute>
                    <Expenses />           
                </PrivateRoute>
                
            } />
            <Route path='*' element={
                <PageNotFound />
            } />

        </Routes>
    </>)
    
}

export default Main