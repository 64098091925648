import { Autocomplete, Grid, IconButton, TextField } from "@mui/material";
import React from "react";
import { MdOutlinePlaylistAdd } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import "./ProductsForm.scss";
import './ProductFormResponsive.scss'

/**
 *
 * @param {*} rowItem
 * @param {*} index
 * @param {*} itemOptions
 * @param {*} handleInput
 * @param {*} handleItemChange
 * @param {*} itemArray
 * @param {*} errors
 * @param {*} setItemArray
 * @param {*} setErrors
 * @param {*} transaction_type
 * @returns
 */
function RowItems({
  itemOptions,
  handleInput,
  itemArray,
  errors,
  setItemArray,
  setErrors,
  transaction_type,
}) {
  const addItemRow = () => {
    let tempItems = [ ...itemArray ];
    let temp_err = { ...errors };
    tempItems.unshift({
      Item: { itemName: "", id: "" },
      weight: 0,
      wastage: 0,
      touch: 0,
      lowTouch: 0,
      fine: 0,
    });
    temp_err.items.unshift({
      Item: false,
      weight: false,
      wastage: false,
      touch: false,
    });

    setItemArray(tempItems);
    setErrors(temp_err);
  };

  const deleteItemRow = (index) => {
    itemArray.splice(index, 1);

    errors.items.splice(index, 1);

    let tempItems = [...itemArray];
    let temp_err = { ...errors };
    setItemArray(tempItems);
    setErrors(temp_err);
  };

  const handleItemChange = (e, value, reason, index) => {
    let tempItems = [...itemArray];
    if (reason === "clear") {
      tempItems[index].Item.itemName = "";
      tempItems[index].Item.id = "";
      setItemArray(tempItems);
    } else {
      tempItems[index].Item = value;
      setItemArray(tempItems);
    }

    let temp_errs = { ...errors };
    temp_errs.items[index]["Item"] = false;
    setErrors(temp_errs);
  };

  return (
    <>
      {itemArray?.map((rowItem, index) => (
        <>
          <Grid
            item
            xs={12}
            lg={2.2}
            md={2.2}
            key={"ac Grid" + rowItem.Item.itemName + index}
          >
            <Autocomplete
              key={"autocomplete" + rowItem.Item.itemName + index}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Item Name"
                  required
                  error={errors.items[index].Item}
                  helperText={errors.items[index].Item}
                  variant="standard"
                  key={
                    "autocomplete-textfield-" + rowItem.Item.itemName + index
                  }
                />
              )}
              options={structuredClone(itemOptions)}
              getOptionLabel={(option) => option.itemName}
              clearOnEscape
              noOptionsText="No Items Found"
              onChange={(e, value, reason) =>
                handleItemChange(e, value, reason, index)
              }
              value={rowItem.Item}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={transaction_type === "orders" ? 1.6 : 2}
            md={transaction_type === "orders" ? 1.4 : 1.8}
            key={"weight-grid-" + index}
          >
            <TextField
              key={"weight textfield" + index}
              required
              id="weight"
              name={"weight"}
              label="Weight (g)"
              fullWidth
              variant="standard"
              type="number"
              value={rowItem.weight}
              onChange={(e) => handleInput(e, index)}
              error={errors.items[index].weight}
              helperText={errors.items[index].weight}
            />
          </Grid>
          
          <Grid
            item
            xs={12}
            lg={transaction_type === "orders" ? 1.6 : 2}
            md={transaction_type === "orders" ? 1.4 : 1.8}
            key={"touch-grid-" + index}
          >
            <TextField
              key={"touch textfield" + index}
              required
              id="touch"
              name={"touch"}
              label={
                transaction_type === "orders" ? "High Touch (%)" : "Touch (%)"
              }
              fullWidth
              variant="standard"
              type="number"
              value={rowItem.touch}
              onChange={(e) => handleInput(e, index)}
              error={errors.items[index].touch}
              helperText={errors.items[index].touch}
            />
          </Grid>
          {transaction_type === "orders" && (
            <Grid item xs={12} lg={1.4} md={1.2} key={"lowTouch-grid-" + index}>
              <TextField
                key={"lowTouch textfield" + index}
                required
                id="ltouch"
                name={"lowTouch"}
                label="Low Touch (%)"
                fullWidth
                variant="standard"
                type="number"
                value={rowItem.lowTouch}
                onChange={(e) => handleInput(e, index)}
                error={errors.items[index].touch}
                helperText={errors.items[index].touch}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            lg={transaction_type === "orders" ? 1.6 : 2}
            md={transaction_type === "orders" ? 1.4 : 1.8}
            key={"wastage-grid-" + index}
          >
            <TextField
              key={"wastage textfield" + index}
              required
              id="wastage"
              name={"wastage"}
              label="Wastage (%)"
              fullWidth
              variant="standard"
              type="number"
              value={rowItem.wastage}
              onChange={(e) => handleInput(e, index)}
              helperText={errors.items[index].wastage}
              error={errors.items[index].wastage}
            />
          </Grid>
          <Grid
            item
            xs={6}
            lg={transaction_type === "orders" ? 1.6 : 2}
            md={transaction_type === "orders" ? 1.4 : 1.8}
            key={"fine-grid-" + index}
          >
            <TextField
              key={"fine textfield" + index}
              required
              id="fine"
              name={"fine"}
              label="Fine (g)"
              fullWidth
              variant="standard"
              type="number"
              disabled
              value={Math.round((rowItem.fine + Number.EPSILON) * 100) / 100}
            />
          </Grid>
          {index > 0 && (
            <Grid
              item
              xs={6}
              sm={0.5}
              className="delete-icon-grid"
              key={"delete-icon-grid-" + index}
            >
              <TiDelete
                key={"delete-button" + index}
                size={30}
                onClick={() => deleteItemRow(index)}
              />
            </Grid>
          )}

          {index === 0 && (
            <Grid
              item
              className="add-row-btn-grid"
              key={"add-icon-grid-" + index}
            >
              <IconButton
                className="icon-button"
                key={"add-button" + index}
                onClick={addItemRow}
              >
                <MdOutlinePlaylistAdd key={"add-icon" + index} size={24} />
                <span
                  key={"add-button-span" + index}
                  style={{ fontSize: 14, marginLeft: 5 }}
                >
                  Add Item ({itemArray?.length - 1})
                </span>
              </IconButton>
            </Grid>
          )}
        </>
      ))}
    </>
  );
}

export default RowItems
