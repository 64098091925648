import {axiosInstance} from './Common'


export const getPartyTransactions = (params) => {
    return axiosInstance.get('party-transactions',{params : params})
}

export const deletePartyTransactions = (ids) => {
    return axiosInstance.delete('party-transactions',{data:ids})
}

export const getAllSelectedPartyTransactions = () => {
    return axiosInstance.get('party-transactions/all-selected')
}


