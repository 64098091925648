import {axiosInstance} from './Common'

export const authenticateUser = (credentials) => {
    return axiosInstance.post('auth/login',credentials)
}

export const logout = (token) => {
    return axiosInstance.post('auth/logout',{token:token})
}

export const forgotPassword = (username) => {
    return axiosInstance.post('auth/forgot-password',{username:username},{timeout:120000})
}

export const resetPassword = (data) => {
    return axiosInstance.post('auth/reset-password',data,{timeout:120000})
}

export const resetLoggedInPassword = (id) => {
    return axiosInstance.post('auth/reset-password-logged-in',{id},{timeout:120000})
}
export const getUsers = (params) => {
    return axiosInstance.get('users',{params:params})
}
export const addUser = (data) => {
    return axiosInstance.post('users',data)
}
export const updateUser = (data) => {
    return axiosInstance.put('users',data)
}
export const deleteUsers = (ids) => {
    return axiosInstance.delete('users',{data:ids})
}

export const getUserInfo = () => {
    return axiosInstance.get('users/user-info')
}

export const getUserInfoById = (id) => {
    return axiosInstance.get('users/user-info/'+id)
}

export const getAllSelectedUsers = () => {
    return axiosInstance.get('users/all-selected')
}
