import React from 'react'
import Alert from '@mui/material/Alert';
import TimedLinearProgress from '../TimedLinearProgress/TimedLinearProgress';
import './CustomAlert.scss'

/**
 * @param {Boolean} [popup]
 * @param {React.ReactNode} [props.children]
 * @param {String} flag 
 * @param {Number} [timeout]
 * @param {RefObject} [scrollRef]
 * @param {React.ComponentState} setAlertMsg
 * @param {String} [className]
 * @returns {JSX.Element}
 */

function CustomAlert ({popup=false,children,flag,timeout,scrollRef,setAlertMsg,className}){
    if(typeof timeout === 'number'){
        setTimeout(() => {
            setAlertMsg({message:'',flag:''})
        },timeout)
    }
    
    if(scrollRef){
        scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start"})
    }else{
        window.scrollTo({top:0,behavior:'smooth'})
    }

    const colorMap = {
        error:"#d32f2f",
        info:"#0288d1",
        success:"#2e7d32",
        warning:"ed6c02"
    }
    
    return(
            <div className={className+` custom-alert-popup-${popup}`} >
                <Alert severity={flag}>{children}</Alert>
                <TimedLinearProgress  timeLimit={timeout} color = {colorMap[flag]} />
            </div>
    )

}

export default React.memo(CustomAlert)