import axios from 'axios'
import baseUrl from './baseUrl';

export const axiosInstance = axios.create({
	baseURL: baseUrl,
	// timeout: 125000,
	headers: {
		"Accept": 'application/json',
	},
    withCredentials:true,
});

axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
axiosInstance.defaults.headers.put["Content-Type"] = "application/json";
axiosInstance.defaults.headers.patch["Content-Type"] = "application/json";


axiosInstance.interceptors.request.use(
	(req) => {
		const token = 'Bearer '+ localStorage.getItem('token')
		req.headers.Authorization = token
		return req
	},
	(err) => err 
)

axiosInstance.interceptors.response.use(
	(res) => res,
	(err) => {
		console.log("SERVER ERROR",err)
		if(err?.code === 'ECONNABORTED'){
			alert("Cannot connect to the server. Please connect to the internet and refresh.")
			window.location.reload()
		}
		if (err?.response?.status === 401 && ( ['Access token has expired','jwt expired','verification failed'].includes(err?.response?.data?.msg))){
			localStorage.clear()
			window.location.href = '/login' 
		}
		return Promise.reject(err)

	}
)


