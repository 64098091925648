import React from 'react'
import ProductList from '../ProductList';
import PageHeader from '../../Common/PageHeader/PageHeader';
import * as Api from '../../../apis/SellProducts';
import {AiOutlineAppstoreAdd} from 'react-icons/ai'
import { Button } from '@mui/material';
import {Link} from 'react-router-dom'

import './SellProducts.scss'
import columns from './SellProductsColumns';
  
function SellProducts(){

    const addButton = (
      <Link to='/products-form/outwards' style={{textDecoration:'none'}}>
      <Button  variant='contained' color="info">
        Add Product &nbsp; <AiOutlineAppstoreAdd size={23}  />
      </Button>
      </Link>
    )
    return(
        <div className='SellProducts'>
            <PageHeader heading='Outwards Products' />
            {/* <div className='d-flex justify-content-end mb-2 pe-4'>
              {addButton}
            </div> */}
            <ProductList 
              columns = {columns}
              addButton={addButton}
              getRowData = {Api.getSoldProducts}
              getAllSelectedIds = {Api.getAllSelectedProductIds}
              rowLink={'/products-form'}
              rowLinkParams = {'sellProducts'}
              deleteRowData = {Api.deleteSoldProducts}
              dateFilter = {true}
            />
      </div>
    )
}

export default SellProducts