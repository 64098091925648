import React,{useState,useEffect, useMemo} from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@mui/material';
import {TextField,Button } from '@mui/material';
import {AiOutlineUserAdd} from 'react-icons/ai'
import {MdSystemUpdateAlt} from 'react-icons/md'
import CustomAlert from '../../Common/Alert/CustomAlert';

function PartyPopup(props){
    const [partyName,setPartyName] = useState('')
    const [error,setError] = useState(false)
    const [alertMsg,setAlertMsg] = useState({msg:'',flag:''})
    
    const addParty = () => {

        if(!partyName){
            console.log('in error')
            setError('This field cannot be left empty')
            return
        }

        props.addPartyApi(partyName)
        .then((res) =>  {
            setAlertMsg({msg:'Party Added Successfully',flag:'success'})
            setTimeout(() => {
                setAlertMsg({msg:'',flag:''})
            },[5000])
            setPartyName('')
            props.reloadParties()
            
            
        })
        .catch((err) => {
            console.log(err.response)
            setAlertMsg({msg:err.response.data.msg,flag:'error'})
            setTimeout(() => {
                setAlertMsg({msg:'',flag:''})
            },[5000])

        })
    }

    const renderCustomAlert = useMemo(() => {
        return alertMsg.msg ? (
          <CustomAlert
            popup={true}
            flag={alertMsg.flag}
            setAlertMsg={setAlertMsg}
            timeout={alertMsg.timeout}
          >
            {alertMsg.msg}
          </CustomAlert>
        ) : null;
      }, [alertMsg]);

    const updateParty = () => {
        if(!partyName){
            setError('This field cannot be left empty')
            return
        }
        props.updatePartyApi({partyName:partyName,id:props.editedParty.id})
        .then((res) =>  {
            setAlertMsg({msg:'Party Updated Successfully',flag:'success'})
            setTimeout(() => {
                setAlertMsg({msg:'',flag:''})
            },[5000])
            props.reloadParties()
            // props.setOpen(false);
            
        })
        .catch((err) => {
            console.log(err.response)
            setAlertMsg({msg:err.response.data.msg,flag:'error'})
            setTimeout(() => {
                setAlertMsg({msg:'',flag:''})
            },[5000])

        })
    }

    const handleClose = () => {
        setAlertMsg({msg:"",flag:""})
        props.setOpen(false)
        setError(false);
    }

    const changePartyName = (newName) => {
        setPartyName(newName);
        setError(false);
    }

    useEffect(() => {
        if(props.editedParty?.id){
            setPartyName(props.editedParty.partyName)
        }else{
            setPartyName('')
        }
        
    },[props.editedParty])

    return(
        <Dialog open={props.open}
        onBackdropClick={handleClose}
        className='PartyPopup'
        >
            {renderCustomAlert}
            <h4  align="center">{!props.editedParty?.id?"ADD PARTY":"EDIT PARTY"}</h4>
            <DialogContent 
                sx={{
                    padding:5,
                    paddingTop:2,
                }}
            >
                <DialogContentText>
                    Provide party name in the below field
                    <br /> 
                    <i>* Party names must be unique</i>
                </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="partyName"
                        label="Party Name"
                        fullWidth
                        variant="standard"
                        value={partyName??""}
                        onChange={(e) => changePartyName(e.target.value)}
                        helperText = {error}
                        error = {error}
                    />
            </DialogContent>
            <DialogActions
            sx={{
                padding:5,
                paddingTop:0
            }}
            className='d-flex justify-content-between'
            >
                <Button variant='contained' onClick={handleClose}>Close</Button>
                {!props.editedParty?.id?
                <Button variant='contained' color="success"  onClick={addParty}><AiOutlineUserAdd size={28}/>  Add  </Button>
                :
                <Button variant='contained' color="success"  onClick={updateParty}><MdSystemUpdateAlt style={{color:'white',marginRight:10}} size={24}/>  Update  </Button>
                }
            </DialogActions>
        </Dialog>
    )
}

export default PartyPopup