import pdfMake from "pdfmake/build/pdfmake";

/**
 * @param {String} fileName
 * @param {Object} product
 * @param {String} partyName
 * @param {Number} totalPartyFine
 * @returns {String}
 */
function generateInvoice(fileName, product, partyName, totalPartyFine) {
    console.log("🚀 || generateInvoice || totalPartyFine:", totalPartyFine)

    try {
  
      var productId = product.id;
      var transactionType = product.transactionType;
      var dateTime = new Date(product.dateTime).toLocaleString();
      var note = product.note ?? " ";
      var __dirname = window.location.origin
      var fonts = {
        Roboto: {
            normal: __dirname + "/fonts/Roboto/Roboto-Regular.ttf",
            bold: __dirname + "/fonts/Roboto/Roboto-Medium.ttf",
            italics: __dirname + "/fonts/Roboto/Roboto-Italic.ttf",
            bolditalics: __dirname + "/fonts/Roboto/Roboto-MediumItalic.ttf",
          },
          Gujarati: {
            normal: __dirname + "/fonts/Gujarati/soGUJ010.TTF",
            bold: __dirname + "/fonts/Gujarati/soGUJ010Bold.TTF",
            italics: __dirname + "/fonts/Gujarati/soGUJ010Italic.TTF",
            bolditalics: __dirname + "/fonts/Gujarati/soGUJ010BI.TTF",
          },
      };
  
      var pdfItems = [];
      var totalFine = 0;
      product.ProductItemLists.forEach((itemData, index) => {
        totalFine += itemData.fine;
  
        pdfItems.push([
          index + 1,
          itemData.Item?.itemName,
          itemData.weight,
          itemData.touch,
          itemData.wastage,
          itemData.fine,
        ]);
      });
  
      console.log("pdfItems", pdfItems);
      var docDefinition = {
        watermark: {
          text: "RS Silver",
          color: "grey",
          opacity: 0.2,
          bold: true,
          italics: false,
        },
        content: [
          {
            text: "zL U6[XFI GDo",
            style: "mainHeader",
            font: "Gujarati",
            bold: true,
            margin: [0, 0, 40, 0],
          },
          {
            relativePosition: { x: 0, y: 3 },
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 490,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
          {
            text: `${transactionType?.toUpperCase()} INVOICE`,
            style: "mainHeader",
            margin: [0, 25, 40, 15],
          },
          {
            relativePosition: { x: 0, y: 5 },
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 490,
                y2: 0,
                lineWidth: 1,
              },
            ],
          },
          {
            table: {
              widths: ["33%", "33%", "33%"],
              body: [
                [
                  { text: "Date & Time", style: "columnHeader" },
                  { text: "Party Name", style: "columnHeader" },
                  { text: "Invoice No:", style: "columnHeader" },
                ],
                [dateTime, partyName, productId],
              ],
            },
            layout: "noBorders",
            margin: [-40, 20, 0, 20],
            alignment: "center",
          },
          {
            table: {
              widths: "*",
              body: [
                [
                  { text: "Sr. No", style: "tableHeader" },
                  { text: "Product", style: "tableHeader" },
                  { text: "Weight", style: "tableHeader" },
                  { text: "Touch", style: "tableHeader" },
                  { text: "Wastage", style: "tableHeader" },
                  { text: "Fine", style: "tableHeader" },
                ],
                ...pdfItems,
              ],
            },
            layout: "lightHorizontalLines",
            alignment: "center",
            margin: [0, 0, 40, 25],
          },
          {
            table: {
              widths: [490],
              heights: 30 * Math.ceil(note.length / 80),
              body: [[{ text: `Additional Notes: ${note}` }]],
            },
          },
          {
            table: {
              widths: [50, "*", 230, 65, "*"],
              body: [
                [
                  { text: "Balance:", style: "columnHeader" },
                  { text: totalPartyFine },
                  { text: "" },
                  { text: "Total Fine:", style: "columnHeader", margin: [0, 0] },
                  { text: totalFine },
                ],
              ],
            },
            layout: "noBorders",
            margin: [0, 25, 0, 0],
            alignment: "left",
          },
        ],
        styles: {
          mainHeader: {
            fontSize: 15,
            alignment: "center",
          },
          columnHeader: {
            bold: true,
            fontSize: 13,
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            alignment: "center",
            margin: [0, 0, 0, 8],
          },
          line: {
            margin: 0,
          },
        },
        pageSize: "A4",
        pageMargins: [50, 20, 10, 20],
      };
  
      var options = {
        // ...
      };
  
      pdfMake.createPdf(docDefinition, options,fonts).download(fileName);
      
    } catch (error) {
      throw error;
    }
  }
  
 export default generateInvoice;
  