import {axiosInstance} from './Common'

export const getSoldProducts = (params) => {
    return axiosInstance.get('sell-products',{params})
}

export const getAllSelectedProductIds = () => {
    return axiosInstance.get('sell-products/all-selected')
}

export const deleteSoldProducts = (ids) => {
    return axiosInstance.delete('sell-products',{data:ids})
}