import {axiosInstance} from './Common'

export const getExpenses = (params) => {
    return axiosInstance.get('expenses',{params:{...params}})
}

export const addExpense = (expense) => {
    return axiosInstance.post('expenses',expense)
}

export const updateExpense = (expense) => {
    return axiosInstance.patch('expenses',expense)
}

export const deleteExpense = (ids) =>{
    return axiosInstance.delete('expenses',{data:ids})
}

export const getExpenseDetails = (item_id) => {
    return axiosInstance.get('expenses/details/'+item_id)
}

export const getAllSelectedExpenses = () => {
    return axiosInstance.get('expenses/all-selected')
}