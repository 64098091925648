import { Chip } from "@mui/material";
import dayjs from "dayjs";

const formatDate = (params) => {
    let tempDate = dayjs(params.value).format("DD/MM/YYYY h:mm:ss A");
    return tempDate;
  };

const columns = [
    {
      field: "id",
      headerName: "REF NO",
      width: 100,
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "dateTime",
      headerName: "DATE",
      width: 120,
      valueFormatter: formatDate,
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "Party",
      headerName: "PARTY NAME",
      width: 160,
      valueFormatter: (params) => params.value.partyName,
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "item",
      headerName: "ITEM",
      width: 150,
      sortable: false,
      cellClassName: "flex-column align-items-baseline",
      renderCell: (params) => (
        <div className="mt-auto mb-auto">
          {params.row.OrderItemLists.map((itemObj, index) => (
            <div className="mt-2 mb-2">
              {index + 1}. &nbsp;{itemObj?.Item?.itemName}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: "weight",
      headerName: "WEIGHT (g)",
      width: 110,
      sortable: false,
      renderCell: (params) => (
        <div className="mt-auto mb-auto">
          {params.row.OrderItemLists.map((itemObj) => (
            <div className="mt-2 mb-2">{itemObj?.weight}</div>
          ))}
        </div>
      ),
    },
    {
      field: 'touch',
      headerName: 'TOUCH (%)',
      width: 100,
      sortable:false,
      renderCell:(params) => (
        <div className='mt-auto mb-auto'>
        {params.row.OrderItemLists.map((itemObj,index) => (
          <div className='mt-2 mb-2'>{itemObj?.lowTouch} - {itemObj?.touch}</div>
        ))}
        </div>
      )
    },
    {
      field: "wastage",
      headerName: "WASTAGE (%)",
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <div className="mt-auto mb-auto">
          {params.row.OrderItemLists.map((itemObj, index) => (
            <div className="mt-2 mb-2">{itemObj?.wastage} </div>
          ))}
        </div>
      ),
    },
    {
      field: "fine",
      headerName: "FINE (g)",
      width: 110,
      sortable: false,
      renderCell: (params) => (
        <div className="mt-auto mb-auto">
          {params.row.OrderItemLists.map((itemObj, index) => (
            <div className="mt-2 mb-2">{itemObj?.fine}</div>
          ))}
        </div>
      ),
    },
    {
      field: 'orderStatus',
      headerName: 'STATUS',
      width:150,
      sortingOrder:['asc','desc'],
      renderCell:(params) => (
        params.row.orderStatus === true?
        <Chip label='COMPLETED' color='success' style={{width:110,fontWeight:'bold',color:'#f2f2f2'}} />
        :
        <Chip label='PENDING' color='warning' style={{width:110,fontWeight:'bold',color:'#f2f2f2'}}/>
      )
    },
    {
      field: "note",
      headerName: "ADDITIONAL NOTES",
      width: 270,
      sortingOrder: ["asc", "desc"],
    },
    {
      field: "updatedByUser",
      headerName: "LAST ENTRY BY",
      width: 180,
      sortingOrder: ["asc", "desc"],
      valueFormatter: (params) => params.value.username,
    },
    {
      field: "updatedAt",
      headerName: "LAST ENTRY ON",
      width: 180,
      sortingOrder: ["asc", "desc"],
      valueFormatter: formatDate,
    },
  ];

  export default columns