import './PageHeader.scss'
import {BiArrowBack} from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

const PageHeader = (props) => {
const nav = useNavigate()
return(
    <div className="page-header">
        {!props.noBackArrow?
        <BiArrowBack className='backIcon' size={50} onClick={() => nav(-1 || '/')}  />
        :
        null
        }
        <h2 className='d-flex pb-3 mb-3 justify-content-center '>
              
            <span>
            {props.heading?props.heading.toUpperCase():props.html} 
            </span>
        </h2>
        {!props.noLines?
        <div className='divider'></div>
        :
        null}
    </div>
)

}

export default PageHeader