import React,{useState} from 'react'
import { Grid,Paper, Avatar, TextField, Button, Typography,InputAdornment,CircularProgress  } from '@mui/material'
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {MdVisibility,MdVisibilityOff} from 'react-icons/md'
import './Login.scss'
import * as Api from '../../apis/User'
import { useNavigate,Link,useLocation } from 'react-router-dom';

function Login() {
    const paperStyle={padding :25,width:480, margin:"20px auto"}
    const avatarStyle={backgroundColor:'#1bbd7e',width:100,height:100,marginBottom:20}
    const btnstyle={margin:'8px 0 13px 0'}
    const [visible,setVisible] = useState(false)
    const [errors,setErrors] = useState({username:'',password:''})
    const [input,setInput] = useState({username:'',password:'',rememberMe:''})
    const [alertMsg, setAlertMsg] = useState({mag:'',flag:''})
    const [loading,setLoading] = useState(false)
    const nav = useNavigate()
    const location = useLocation()

    const handleInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        setInput({...input,[name]:value})
        setErrors({...errors,[name]:''})
    }

    const handleSubmit = () => {
            let temp = {}
            for(let i in input){
                if(!input[i] & i !== 'rememberMe'){
                    temp[i] = "This field cannot be left empty"
                }
            }
            setErrors(temp)
            if(Object.keys(temp).length !== 0){
                return
            }
            setLoading(true)
            Api.authenticateUser(input)
            .then((res) => {
                if(res?.data?.error === false){
                    localStorage.setItem('token',res?.data?.token)
                    localStorage.setItem('username',res.data?.user?.username)
                    if(localStorage.getItem('token')){
                        nav('/',{replace:true})
                    }

                }
                setLoading(false)
            })
            .catch((err) => {
                if(err?.response?.status === 401){
                    setAlertMsg({msg:err.response?.data?.msg,flag:'error'})
                }
                setLoading(false)
            })
            
    }

    return(
        <Grid>
            <Paper elevation={10} style={paperStyle}>
                {!alertMsg.flag && location?.state?.flag?
                <Alert severity={location?.state.flag} className='mb-3 '>{location?.state.msg}</Alert>
                :null}
                <Grid align='center' className='mb-2'>

                     <Avatar  style={avatarStyle}></Avatar>
                     <Typography variant='h4'>Log In  </Typography >
                </Grid>
                {alertMsg.flag?
                <Alert severity={alertMsg.flag} className='mb-1 mt-1'>{alertMsg.msg}</Alert>
                :null}
                <TextField autoComplete='on' onChange={handleInput} error={errors.username} helperText={errors.username} name='username' label='Username' className='mb-3 mt-2' placeholder='Enter username' variant="outlined" fullWidth required/>
                <TextField onChange={handleInput} error={errors.password} helperText={errors.password} name='password' label='Password' placeholder='Enter password' type={visible?'text':'password'} variant="outlined" 
                InputProps={{
                    endAdornment: <InputAdornment onClick={() => setVisible(!visible)} className='me-2' style={{cursor:'pointer'}}  position="end">
                                    {visible?<MdVisibility size={24} />:<MdVisibilityOff size={24} />}
                                  </InputAdornment>,
                  }}                
                fullWidth required/>
                <FormControlLabel
                    control={
                    <Checkbox
                        name="rememberMe"
                        color="primary"
                        onChange={handleInput}
                    />
                    }
                    label="Remember me"
                 />
                <Button onClick={handleSubmit} type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>
                    Sign in {loading?<CircularProgress size={20} style={{color:'#fff',marginLeft:10}} />:null}
                </Button>
                <Typography >
                     <Link style={{cursor:'pointer'}} to='/forgot-password'>Forgot Password ?</Link>
                </Typography>
                <Typography variant='body2' className='mt-1'>*If you do not have an account then ask the admin to create one for you.
                     
                </Typography>
            </Paper>
        </Grid>
    )

}

export default Login