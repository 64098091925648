import './HoverButton.scss'

const HoverButton = (props) => {
    return(
        <div className='hover-button-wrap'>
            <button className='hover-btn' onClick={props.onClick}>
                <span>
                {props.children}
                </span>
            </button>
        </div>
    )
}

export default HoverButton