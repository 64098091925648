import React,{ useState,useMemo} from 'react'
import {Button} from '@mui/material'
import ProductList from '../ProductList';
import PageHeader from '../../Common/PageHeader/PageHeader';
import PartyPopup from './PartyPopup';
import {AiOutlineUsergroupAdd,AiOutlineEdit} from 'react-icons/ai'
import * as Api from '../../../apis/PartyList';

import './PartyList.scss'
  
function PartyList(){
    const [openPopup,setOpenPopup] = useState(false)
    const [reloadParties,setReloadParties] = useState(false)
    const [editedParty,setEditedParty] = useState('')

    const handleEditParty = (params) => {
        setEditedParty({partyName:params.row.partyName,id:params.id})
        setOpenPopup(true)
    }

    const handleAddParty = () => {
        setEditedParty({partyName:'',id:''})
        setOpenPopup(true)
    }

    const columns = [
        {
            field: 'partyName',
            headerName: 'PARTY NAME',
            flex:2,
            sortingOrder:['asc','desc']
        },
        {
            field: 'edit',
            headerName: 'EDIT PARTY',
            flex:1,
            sortable:false,   
            renderCell:(params) => (
                <div className='edit-button-col' onClick={((e) => e.stopPropagation())}>
                  <Button
                    variant="contained"
                    size="small"
                    className='edit-button'
                    tabIndex={-1}
                    onClick = {() => handleEditParty(params)}
                  >
                     EDIT <AiOutlineEdit size={20} />
                  </Button>
                </div>
              )
        },
    ]

    const toggleReloadParties = () => {
        setReloadParties(!reloadParties)
    }

    const addButton = (
            <Button onClick={handleAddParty} variant='contained' color="info" >
                Add Party <AiOutlineUsergroupAdd size={23}  />
            </Button>
        )
    
    const ListRender = useMemo(() => {
        return (<ProductList 
            columns = {columns}
            getRowData = {Api.getPartyList}
            getAllSelectedIds = {Api.getAllSelectedParties}
            addButton = {addButton}
            reloadRowData = {reloadParties}
            placeholder="Search by Party Name"
            initalField = 'partyName'
            initialSort = 'asc'
            deleteRowData = {Api.deleteParty}
            rowLink='/party-transactions'
            rowLinkParams = 'partyLists'
        />)
    },[reloadParties])
    
    return(
        <div className='PartyList'>
            <PartyPopup 
            autoFocus
            open={openPopup} 
            setOpen={setOpenPopup} 
            addPartyApi = {Api.addParty} 
            updatePartyApi = {Api.updateParty} 
            reloadParties = {toggleReloadParties}
            editedParty={editedParty}
            />
            <PageHeader heading='Party list' />
            {ListRender}
            
      </div>
    )
}

export default PartyList