import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { Grid,Paper,  TextField, Button, Typography,CircularProgress  } from '@mui/material'
import { RiLockPasswordFill  } from 'react-icons/ri'
import Alert from '@mui/material/Alert';
import {BiArrowBack} from 'react-icons/bi'
import '../Login.scss'
import '../LoginResponsive.scss'
import * as Api from '../../../apis/User'

function ForgotPassword() {
    const paperStyle={padding :25,width:480, margin:"20px auto"}
    const [errors,setErrors] = useState({username:''})
    const [input,setInput] = useState({username:''})
    const [alertMsg, setAlertMsg] = useState({mag:'',flag:''})
    const [loading,setLoading] = useState(false)
    const nav = useNavigate()
    const btnstyle={margin:'8px 0 13px 0'}

    const handleInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        setInput({...input,[name]:value})
        setErrors({...errors,[name]:''})
    }

    const handleSubmit = () => {
        setLoading(true)
        setAlertMsg({msg:"",flag:""});
        let temp = {}
        for(let i in input){
            if(!input[i]){
                temp[i] = "This field cannot be left empty"
            }
        }
        setErrors(temp)
        if(Object.keys(temp).length !== 0){
            return
        }
        Api.forgotPassword(input.username).then((res) => {
            setAlertMsg({msg:res?.data?.msg,flag:'success'})
            // setTimeout(() => { setAlertMsg({msg:'',flag:''}) }, 5000)
            
        })
        .catch((err) => {
            setAlertMsg({msg:err?.response?.data?.msg,flag:'error'})
            // setTimeout(() => { setAlertMsg({msg:'',flag:''}) }, 5000)
        }  )
        .finally(() => {
            setLoading(false)
        })
    }

  return (
    <Grid className='ForgotPassword'>
        <div className='d-flex align-items-center iconWrapper' onClick={() => nav('/login')}>
            <BiArrowBack className='backIcon' size={40}   />
            <span>Back to Log In</span>
        </div>
            <Paper elevation={10} style={paperStyle} className='paperStyle'>
                <Grid align='center' className='mb-3'  >
                <RiLockPasswordFill size={125}  className='reset-icon' />
                <Typography variant='h4' >Forgot Password </Typography >
                <Typography variant='subtitle2' className='text-muted' >Enter your registered username </Typography >
                </Grid>
                {alertMsg.msg?
                <Alert severity={alertMsg.flag} className='mb-1 mt-2'>{alertMsg.msg}</Alert>
                :
                null}
                <TextField autoComplete='on' onChange={handleInput} error={errors.username} helperText={errors.username} name='username' label='Username' className='mb-3 mt-2' placeholder='Enter username' variant="outlined" fullWidth required/>
                <Button onClick={handleSubmit} type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>
                    Get Reset Link {loading?<CircularProgress size={20} style={{color:'#fff',marginLeft:10}} />:null}
                </Button>
            </Paper>
        </Grid>
  )
}

export default ForgotPassword
