import React,{useEffect, useState } from 'react'
import ProductList from '../ProductList';
import PageHeader from '../../Common/PageHeader/PageHeader';
import * as Api from '../../../apis/PartyTransactions';
import {getPartyDetails} from '../../../apis/PartyList';
import dayjs from 'dayjs';
import {useParams} from 'react-router-dom'
import { FormControl,InputLabel, Select,MenuItem} from '@mui/material';
// import {Link} from 'react-router-dom'

import './PartyTransactions.scss'
  
function PartyTransactions(){
  const [partyDetails,setPartyDetails] = useState()
  const [transactionType,setTransactionType] = useState('all')

  const {party_id} = useParams()

  const addButton = (
    <FormControl variant="standard" className='transaction-selector'>
    <InputLabel id="transactionType">Transaction Type</InputLabel>
    <Select
        value={transactionType}
        onChange={(e) => setTransactionType(e.target.value)}
        label="Transaction Type"
        labelId = "transactionType"
        >
        <MenuItem value={"all"}>All Products</MenuItem>
        <MenuItem value={"inwards"}>Inwards Products</MenuItem>
        <MenuItem value={"outwards"}>Outwards Products</MenuItem>
    </Select>
  </FormControl>
  )

  const formatDate = (params) => {
    let tempDate = dayjs(params.value).format('DD/MM/YYYY h:mm:ss A')
    return tempDate
  }
  const columns = [
    { field: 'id', headerName: 'REF NO', width: 100 ,sortingOrder:['asc','desc'] },
    {
      field: 'dateTime',
      headerName: 'DATE',
      width: 120,
      valueFormatter:formatDate,
      sortingOrder:['asc','desc']
    },
    {
      field: 'Item',
      headerName: 'ITEM',
      width: 180,
      sortable:false,
      cellClassName:'flex-column align-ProductItemLists-baseline',
      renderCell:(params) => (
        <div className='mt-auto mb-auto'>
        {params.row.ProductItemLists.map((itemObj,index) => (
          <div className='mt-2 mb-2'>{index+1}. &nbsp;{itemObj?.Item?.itemName}</div>
        ))}
        </div>
      )
    },
    {
      field: 'weight',
      headerName: 'WEIGHT (g)',
      width: 110,
      sortable:false,
      renderCell:(params) => (
        <div className='mt-auto mb-auto'>
        {params.row.ProductItemLists.map((itemObj,index) => (
          <div className='mt-2 mb-2'>{itemObj?.weight}</div>
        ))}
        </div>
      )
    },
    {
      field: 'touch',
      headerName: 'TOUCH (%)',
      width: 100,
      sortable:false,
      renderCell:(params) => (
        <div className='mt-auto mb-auto'>
        {params.row.ProductItemLists.map((itemObj,index) => (
          <div className='mt-2 mb-2'>{itemObj?.touch}</div>
        ))}
        </div>
      )
    },
    {
      field: 'wastage',
      headerName: 'WASTAGE (%)',
      width: 120,
      sortable:false,
      renderCell:(params) => (
        <div className='mt-auto mb-auto'>
        {params.row.ProductItemLists.map((itemObj,index) => (
          <div className='mt-2 mb-2'>{itemObj?.wastage}</div>
        ))}
        </div>
      )
    },
    {
      field: 'fine',
      headerName: 'FINE (g)',
      width: 110,
      sortable:false,
      renderCell:(params) => (
        <div className='mt-auto mb-auto'>
        {params.row.ProductItemLists.map((itemObj,index) => (
          <div className='mt-2 mb-2'>{itemObj?.fine}</div>
        ))}
        </div>
      )
    },
    {
      field: 'note',
      headerName: 'ADDITIONAL NOTES',
      width:270,
      sortingOrder:['asc','desc']
    },
    {
      field: "updatedByUser",
      headerName: "LAST ENTRY BY",
      width: 180,
      sortingOrder: ["asc", "desc"],
      valueFormatter: (params) => params.value.username,
    },
    {
      field: "updatedAt",
      headerName: "LAST ENTRY ON",
      width: 180,
      sortingOrder: ["asc", "desc"],
      valueFormatter: formatDate,
    }
  ];


    const rowClassName = (params,isRowSelected) => {
      if(params.row.transactionType === 'inwards'){
        return isRowSelected?'row-class-buy buy-selected':'row-class-buy'
      }else{
        return isRowSelected?'row-class-sell sell-selected':'row-class-sell'
      }
    }

    useEffect(() => {
      getPartyDetails(party_id).then((res) => {
        setPartyDetails(res.data.data)
      })
    },[])
    if(!partyDetails){
      return 
    }
        
    return(
        <div className='PartyTransactions'>
            <PageHeader heading={partyDetails.partyName} />

            <ProductList 
              getRowData = {Api.getPartyTransactions}
              getParams={{party:party_id,transactionType:transactionType}}
              getAllSelectedIds = {Api.getAllSelectedPartyTransactions}
              addButton={addButton}
              rowLink={'/products-form'}
              rowLinkParams = {'partyTransactions'}
              deleteRowData = {Api.deletePartyTransactions}
              columns = {columns}
              placeholder = 'Search by Item or Ref No'
              rowClassName = {rowClassName}
              dateFilter = {true}
            />
      </div>
    )
}

export default PartyTransactions