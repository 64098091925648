import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateRangePicker } from 'rsuite';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField'
import 'rsuite/dist/rsuite.min.css'
import './DateTime.scss'

export const DateTimePickerWrapper = (props) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                label="Pick Date and Time"
                value={props.dateTime}
                onChange={props.changeDateTime}
                inputFormat="DD/MM/YYYY hh:mm a"
                renderInput={(params) => <TextField {...params} fullWidth helperText={props.errorText}/>}
                
            />
        </LocalizationProvider>
    )
}

export const DatePickerWrapper = (props) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                label={props.label??'Pick Date'}
                value={props.date}
                onChange={props.changeDate}
                maxDate={props.maxDate}
                minDate={props.minDate}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => <TextField {...params} fullWidth helperText={props.errorText} />}
                
            />
        </LocalizationProvider>
    )
}

export const DateRangePickerWrapper = (props) => {
    const {afterToday} = DateRangePicker
    const d = dayjs()
    const ranges = [
        {
            label:'Today',
            value:[d.$d, d.$d]
        },
        {
            label:'Last 7 Days',
            value:[d.subtract(6,'day').$d,d.$d]
        },
        {
            label: 'Last 30 Days',
            value: [d.subtract(29,'day').$d, d.$d]
        },
        {
            label: 'This Month',
            value: [d.startOf('month').$d, d.$d]
        },
        {
            label: 'This Year',
            value: [d.startOf('year').$d, d.$d]
        }
    ]

    return(
        <DateRangePicker 
        format = 'dd/MM/yyyy'
        disabledDate={afterToday()}
        character = ' - '
        placeholder={props.placeholder}
        editable={false}
        ranges={ranges}
        className="dateRange"
        size="lg"
        value={props.date}
        onChange={props.changeDate}
    />
    )

}