import React from 'react'
import {Grid} from '@mui/material'
import HomeCard from './HomeCard'
import './Home.scss'
import './HomeResponsive.scss'

import sell from '../../assets/images/sell.jpg'
import buy from '../../assets/images/buy.jpg'
import party from '../../assets/images/party-list.jpg'
import item from '../../assets/images/item-list-home.jpg'
import ordersForm from '../../assets/images/orders-form.jpg'
import orders from '../../assets/images/orders.jpg'
import buyForm from '../../assets/images/buy-products-form.jpg'
import sellForm from '../../assets/images/sell-products-form.jpg'
import expenses from '../../assets/images/expenses.jpg'

function Home(){
    return(
        <div className='home-container'>
            <Grid container spacing={8} padding={4} >
                <Grid item xs={4}>
                    <HomeCard image={buyForm} text='Inwards Form' size="half"  link='products-form/inwards' />
                </Grid>
                <Grid item xs={4}>
                    <HomeCard image={sellForm} text='Outwards Form' size="half"  link='products-form/outwards' />
                </Grid>

                <Grid item xs={4}>
                    <HomeCard image={ordersForm} text='Orders Form' size="half" link='products-form/orders' />
                </Grid>
                <Grid item xs={4}>
                    <HomeCard image={buy} text='Inwards Products' link='buy-products' />
                </Grid>
                <Grid item xs={4}>
                    <HomeCard image={sell} text='Outwards Products' link='sell-products' />
                </Grid>
                <Grid item xs={4}>
                    <HomeCard image={orders} text='Orders' link='orders' />
                </Grid>
                <Grid item xs={4}>
                    <HomeCard image={party} text='Party List' link='party-list' />
                </Grid>
                <Grid item xs={4}>
                    <HomeCard image={item} text='Item List' link='item-list' />
                </Grid>
                <Grid item xs={4}>
                    <HomeCard image={expenses} text='Cash Account' link='cash-account' />
                </Grid>
            </Grid>

        </div>
    )
}

export default Home