import React from 'react'
import ProductList from '../ProductList';
import PageHeader from '../../Common/PageHeader/PageHeader';
import * as Api from '../../../apis/BuyProducts';
import {AiOutlineAppstoreAdd} from 'react-icons/ai'
import { Button } from '@mui/material';
import {Link} from 'react-router-dom'

import './BuyProducts.scss'
import columns from './BuyProductsColumns';
  
function BuyProducts(){

    const addButton = (
      <Link to='/products-form/inwards' style={{textDecoration:'none'}}>
      <Button  variant='contained' color="info">
        Add Product &nbsp; <AiOutlineAppstoreAdd size={23}  />
      </Button>
      </Link>
    )
    return(
        <div className='BuyProducts'>
            <PageHeader heading='Inwards Products' />
            {/* <div className='d-flex justify-content-end mb-2 pe-4'>
              {addButton}
            </div> */}
            <ProductList 
              columns = {columns}
              addButton={addButton}
              getRowData = {Api.getBoughtProducts}
              getAllSelectedIds = {Api.getAllSelectedProductIds}
              rowLink={'/products-form'}
              rowLinkParams = {'buyProducts'}
              deleteRowData = {Api.deleteBoughtProducts}
              dateFilter = {true}
            />
      </div>
    )
}

export default BuyProducts