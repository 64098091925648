import React,{useState} from 'react'
import { useNavigate,useParams } from 'react-router-dom';
import { Grid,Paper, InputAdornment, TextField, Button, Typography,CircularProgress  } from '@mui/material'
import PasswordChecklist from "react-password-checklist"
import { MdResetTv  } from 'react-icons/md'
import Alert from '@mui/material/Alert';
import {BiArrowBack} from 'react-icons/bi'
import {MdVisibility,MdVisibilityOff} from 'react-icons/md'
import '../Login.scss'
import * as Api from '../../../apis/User'

function ResetPassword() {
    const paperStyle={padding :25,width:480, margin:"20px auto"}
    const [errors,setErrors] = useState({newPassword:false,confirmPassword:false})
    const [input,setInput] = useState({newPassword:'',confirmPassword:''})
    const [alertMsg, setAlertMsg] = useState({mag:'',flag:''})
    const [visible,setVisible] = useState([false,false])
    const [loading,setLoading] = useState(false)
    const nav = useNavigate()
    const {token} = useParams()
    const btnstyle={margin:'8px 0 13px 0'}

    const handleInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        setInput({...input,[name]:value})
    }

    const handleSubmit = () => {
        setAlertMsg("");
        console.log('errors', input)
        if(errors.newPassword || errors.confirmPassword){
            setAlertMsg({msg:"Resolve all the errors first",flag:'error'})
            // setTimeout(() => { setAlertMsg({msg:'',flag:''}) }, 3000)
            return
        }
        setLoading(true)
        
        let data = {
            token:token,
            newPassword:input.newPassword
        }
        Api.resetPassword(data).then((res) => {
            nav('/login',{state:{msg:res?.data?.msg,flag:'success'}})
        })
        .catch((err) => {
            setAlertMsg({msg:err?.response?.data?.msg,flag:'error'})
            // setTimeout(() => { setAlertMsg({msg:'',flag:''}) }, 5000)
        })
        .finally(() => {
            setLoading(false)
        })
    }

  return (
    <Grid className='ResetPassword'>
        <div className='d-flex align-items-center iconWrapper' onClick={() => nav('/login')}>
            <BiArrowBack className='backIcon' size={40}   />
            <span>Back to Home</span>
        </div>
            <Paper elevation={10} style={paperStyle}>
                <Grid align='center' className='mb-3'  >
                <MdResetTv size={125}  className='reset-icon' />
                <Typography variant='h4' >Reset Password </Typography >
                <Typography variant='subtitle2' className='text-muted' >You can reset your password here </Typography >
                </Grid>
                {alertMsg.msg?
                <Alert severity={alertMsg.flag} className='mb-1 mt-2'>{alertMsg.msg}</Alert>
                :
                null}
                <TextField 
                    onChange={handleInput} 
                    error={errors.newPassword} 
                    name='newPassword' 
                    label='New Password' 
                    className='mb-3 mt-2' 
                    placeholder='Enter New Password' 
                    variant="outlined" 
                    type={visible[0]?'text':'password'}
                    InputProps={{
                        endAdornment: <InputAdornment onClick={() => setVisible([!visible[0],visible[1]])} className='me-2' style={{cursor:'pointer'}}  position="end">
                                        {visible[0]?<MdVisibility size={24} />:<MdVisibilityOff size={24} />}
                                      </InputAdornment>,
                      }}      
                    fullWidth 
                    required
                />
                <PasswordChecklist
                    rules={["minLength","specialChar","number","capital"]}
                    minLength={5}
                    value={input.newPassword}
                    onChange={(isValid) => setErrors({...errors,newPassword:!isValid})}
                    iconSize={14}
                    className='password-check-list'
                />
                <br />
                <TextField 
                    onChange={handleInput} 
                    error={errors.confirmPassword} 
                    name='confirmPassword'
                    label='Confirm Password' 
                    className='mb-3 mt-2' 
                    placeholder='Re-Enter Password' 
                    variant="outlined" 
                    type={visible[1]?'text':'password'}
                    InputProps={{
                        endAdornment: <InputAdornment onClick={() => setVisible([visible[0],!visible[1]])} className='me-2' style={{cursor:'pointer'}}  position="end">
                                        {visible[1]?<MdVisibility size={24} />:<MdVisibilityOff size={24} />}
                                      </InputAdornment>,
                      }}      
                    fullWidth 
                    required
                />
                <PasswordChecklist
                    rules={["match"]}
                    value={input.confirmPassword}
                    valueAgain={input.newPassword}
                    onChange={(isValid) => setErrors({...errors,confirmPassword:!isValid})}
                    iconSize={14}
                    className='password-check-list mb-3'
                />
                <Button onClick={handleSubmit} type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>
                    Change Password {loading?<CircularProgress size={20} style={{color:'#fff',marginLeft:10}} />:null}
                </Button>
            </Paper>
        </Grid>
  )
}

export default ResetPassword
