import React, { useState, useEffect, useRef, useMemo } from "react";
import Grid from "@mui/material/Grid";
import {
  TextField,
  Paper,
  Button,
  Autocomplete,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import PageHeader from "../Common/PageHeader/PageHeader";
import { DateTimePickerWrapper } from "../Common/DateTime/DateTime";
import Spinner from "../Common/Spinner/Spinner";
// import FormControlLabel from '@mui/material/FormControlLabel';
import "./ProductsForm.scss";
import "./ProductFormResponsive.scss";
import dayjs from "dayjs";
import CustomAlert from "../Common/Alert/CustomAlert";

import { ImLoop2 } from "react-icons/im";
import { AiOutlineEnter } from "react-icons/ai";
import { MdSystemUpdateAlt } from "react-icons/md";
import { BsFillFilePdfFill } from "react-icons/bs";

import { useParams, useNavigate } from "react-router-dom";

import * as Api from "../../apis/ProductForm";
import { getUserInfo as getUserInfoApi } from "../../apis/User";
import RowItems from "./RowItems";
import generateInvoice from "./GenerateReport/generateInvoice";

function ProductsForm() {
  const [dateTime, setDateTime] = useState(dayjs(new Date()));

  const [fieldValues, setFieldValues] = useState({
    party: { partyName: "", id: "" },
    note: "",
  });
  const [itemArray, setItemArray] = useState([
    {
      Item: { itemName: "", id: "" },
      weight: 0,
      wastage: 0,
      touch: 0,
      lowTouch: 0,
      fine: 0,
    },
  ]);
  const [userInfo, setUserInfo] = useState();
  const [errors, setErrors] = useState({
    items: [{ Item: false, weight: false, wastage: false, touch: false }],
    party: false,
    dateTime: false,
  });
  const [alertMsg, setAlertMsg] = useState({ msg: "", flag: "" });
  const [partyOptions, setPartyOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isPrintablePdf, setIsPrintablePdf] = useState(false);
  const scrollRef = useRef(null);

  const nav = useNavigate();
  const { transaction_type, id } = useParams();
  if (!["inwards", "outwards", "orders"].includes(transaction_type)) {
    nav("/pagenotfound");
  }

  const generateNewErrorObject = (ITEMS_LENGTH) => {
    let newErrorObject = {
      party: false,
      dateTime: false,
      items: new Array(ITEMS_LENGTH),
    };
    for (let index = 0; index < ITEMS_LENGTH; index++) {
      newErrorObject.items[index] = {
        Item: false,
        weight: false,
        wastage: false,
        touch: false,
      };
    }
    return newErrorObject;
  };

  const handleReset = () => {
    if (!id) {
      setItemArray([
        {
          Item: { itemName: "", id: "" },
          weight: 0,
          wastage: 0,
          touch: 0,
          lowTouch: 0,
          fine: 0,
        },
      ]);
      setFieldValues({
        party: { partyName: "", id: "" },
        note: "",
      });
      setDateTime(dayjs(new Date()));
      setErrors({
        items: [{ Item: false, weight: false, wastage: false, touch: false }],
        party: false,
        dateTime: false,
      });
    } else {
      let tempItems = [];
      setFieldValues({
        party: productDetails.Party,
        note: productDetails.note,
      });

      tempItems = structuredClone([itemArray[0], ...productDetails.itemList]);

      setDateTime(productDetails.dateTime);

      let n = tempItems.length;
      setErrors(generateNewErrorObject(n));
      setItemArray(tempItems);
    }
  };
  var timeout;
  const debounce = (cb, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => cb(), delay ?? 500);
  };

  const handleSubmit = () => {
    if (buttonLoading) {
      return;
    }

    if (itemArray.length <= 1) {
      setAlertMsg({
        msg: "Atleast 1 item needs to be added",
        flag: "error",
        timeout: 4000,
      });
      return;
    }

    var data = {};
    data.dateTime = dateTime.$d;
    data.transactionType = transaction_type;
    data.note = fieldValues.note;
    let temp_errors = structuredClone(errors);

    var hasError = false;
    if (!fieldValues.party?.id) {
      temp_errors["party"] = "This value cannot be empty";
      hasError = true;
    }
    if (!dayjs(data.dateTime).isValid()) {
      temp_errors["dateTime"] = "Invalid Date Value";

      hasError = true;
    }
    temp_errors.items.forEach((errItemObj, index) => {
      for (const [field, value] of Object.entries(errItemObj)) {
        if (index === 0) {
          return;
        }

        if (field === "Item" && !itemArray[index][field]?.itemName) {
          errItemObj.Item = "Item name must be from the avaiable options";
          hasError = true;
        } else if (field !== "Item" && itemArray[index][field] < 0) {
          errItemObj[field] = field + " cannot be less than 0";
          hasError = true;
        } else if (temp_errors.items[index][field] !== false) {
          hasError = true;
        }
      }
    });

    setErrors({ ...temp_errors });

    if (!hasError) {
      setButtonLoading(true);
      data.id = productDetails.id;
      data.partyId = fieldValues.party.id;
      let listItems = structuredClone(itemArray.slice(1));
      listItems.forEach((itemObj) => {
        itemObj.itemId = itemObj.Item.id;
        delete itemObj.Item;
      });

      if (!id) {
        data.createdBy = userInfo.id;
        data.updatedBy = userInfo.id;

        if (transaction_type === "orders") {
          data.OrderItemLists = listItems;
          Api.addOrder(data)
            .then((res) => {
              if (res?.data?.pdfLink) {
                setTimeout(() => {
                  window.open(res?.data?.pdfLink);
                }, 3500);
              }

              handleReset();
              setAlertMsg({
                msg: "Order Added Successfully",
                flag: "success",
                timeout: 3500,
              });
            })
            .catch((err) => console.log(err))
            .finally(() => setButtonLoading(false));
        } else {
          data.ProductItemLists = listItems;
          data.isPrintablePdf = isPrintablePdf
          Api.addProduct(data)
            .then(async (res) => {
              if(isPrintablePdf === true){
                let responseData = res?.data?.data;
                await generateInvoice(
                  responseData.fileName,
                  { ...responseData.product },
                  responseData.partyData.partyName,
                  responseData.partyData.totalFine
                );
              }
              
            })
            .then(() => {
              handleReset();
              setAlertMsg({
                msg: "Product Added Successfully",
                flag: "success",
                timeout: 3500,
              });
            })
            .catch((err) => console.log(err))
            .finally(() => setButtonLoading(false));
        }
      } else {
        data.createdBy = productDetails.createdByUser.id;
        data.updatedBy = userInfo.id;
        if (transaction_type === "orders") {
          data.OrderItemLists = listItems;
          Api.updateOrder(data)
            .then((res) => {
              setAlertMsg({
                msg: "Order Updated Successfully",
                flag: "success",
                timeout: 3500,
              });
            })
            .catch(() =>
              setAlertMsg({
                msg: "Failed to update the product, Please Refresh and Try Agin...",
                flag: "error",
              })
            )
            .finally(() => setButtonLoading(false));
        } else {
          data.ProductItemLists = listItems;
          data.isPrintablePdf = isPrintablePdf
          Api.updateProduct(data)
            .then(async (res) => {
              if(isPrintablePdf === true){
                let responseData = res?.data?.data;
                await generateInvoice(
                  responseData.fileName,
                  { ...responseData.product },
                  responseData.partyData.partyName,
                  responseData.partyData.totalFine
                );
              }             
            }).then(() => {
              setAlertMsg({
                msg: "Product Updated Successfully",
                flag: "success",
                timeout: 5000,
              });
            })
            .catch(() =>
              setAlertMsg({
                msg: "Failed to update the product, Please Refresh and Try Agin...",
                flag: "error",
              })
            )
            .finally(() => setButtonLoading(false));
        }
      }
    } else {
      setAlertMsg({
        msg: "Resolve all the errors first",
        flag: "error",
        timeout: 5000,
      });
    }
  };

  const changeDate = (newValue) => {
    setDateTime(newValue);
    if (!dayjs(newValue).isValid()) {
      setErrors({ ...errors, dateTime: "Date & Time Value Invalid" });
    } else {
      setErrors({ ...errors, dateTime: "" });
    }
  };

  const validateInput = (field, value, index) => {
    var temp_err = errors;
    if (field === "weight") {
      if (value < 0) {
        temp_err.items[index]["weight"] = "Weight cannot be less than 0";
      } else {
        temp_err.items[index]["weight"] = false;
      }
    }
    if (field === "wastage") {
      if (
        temp_err.items[index]["touch"] ===
        "Sum of wastage and touch cannot be greater than 100"
      ) {
        temp_err.items[index]["touch"] = false;
      }
      if (value < 0 || value > 100) {
        temp_err.items[index]["wastage"] = "Wastage must be between 0% - 100%";
      } else if (value + itemArray[index]["touch"] > 100) {
        temp_err.items[index]["wastage"] =
          "Sum of wastage and touch cannot be greater than 100";
      } else {
        temp_err.items[index]["wastage"] = false;
      }
    }
    if (field === "touch" || field === "lowTouch") {
      if (
        temp_err.items[index]["wastage"] ===
        "Sum of wastage and touch cannot be greater than 100"
      ) {
        temp_err.items[index]["wastage"] = false;
      }
      if (value < 0 || value > 100) {
        temp_err.items[index]["touch"] = "Touch must be between 0% - 100%";
      } else if (value + itemArray[index]["wastage"] > 100) {
        temp_err.items[index]["touch"] =
          "Sum of wastage and touch cannot be greater than 100";
      } else if (field === "lowTouch" && itemArray[index]["touch"] < value) {
        temp_err.items[index]["touch"] =
          "Higher touch must be greater than lower touch";
      } else if (
        field === "touch" &&
        itemArray[index]["lowTouch"] > 0 &&
        itemArray[index]["lowTouch"] > value
      ) {
        temp_err.items[index]["touch"] =
          "Higher touch must be greater than lower touch";
      } else {
        temp_err.items[index]["touch"] = false;
      }
    }
    return temp_err;
  };

  const roundToNDecimalPlaces = (value,n=1) =>
    {
      var multiplier = Math.pow(10, n || 0);
      return Math.round(value * multiplier) / multiplier;

    }

  const getFine = (touch, wastage, weight) => {
    if (touch >= 0 && wastage >= 0 && weight >= 0) {
      if (transaction_type !== "orders") {
        return roundToNDecimalPlaces(((touch + wastage) * weight) / 100,1);
      } else {
        return roundToNDecimalPlaces((touch * weight) / 100,1);
      }
    } else {
      return "";
    }
  };

  const handleInput = (e, optionValue) => {
    if (e.target.name === "party") {
      setFieldValues((prev) => ({ ...prev, party: optionValue }));
      setErrors((prev) => ({ ...prev, party: false }));
    } else if (
      ["weight", "wastage", "touch", "lowTouch"].includes(e.target?.name)
    ) {
      let field = e.target.name;
      let index = optionValue;
      let value = Number(e.target.value);
      let tempItems = [...itemArray];
      tempItems[index][field] = value;
      tempItems[index]["fine"] = getFine(
        tempItems[index]["touch"],
        tempItems[index]["wastage"],
        tempItems[index]["weight"]
      );

      setItemArray(tempItems);
      setErrors(validateInput(field, value, index));
    } else {
      let field = e.target.name;
      setFieldValues((prev) => ({ ...prev, [field]: e.target.value }));
    }
  };

  const handlePartyChange = (e, value) => {
    setErrors({ ...errors, party: false });
    setFieldValues({ ...fieldValues, party: value });
  };

  useEffect(() => {
    setFieldValues({
      party: { partyName: "", id: "" },
      note: "",
    });
    setItemArray([
      {
        Item: { itemName: "", id: "" },
        weight: 0,
        wastage: 0,
        touch: 0,
        lowTouch: 0,
        fine: 0,
      },
    ]);
    setErrors({
      items: [{ Item: false, weight: false, wastage: false, touch: false }],
      party: false,
      dateTime: false,
    });
    setAlertMsg({ msg: "", flag: "" });
  }, [transaction_type]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      await getUserInfoApi()
        .then((res) => {
          setUserInfo(res.data?.data);
        })
        .catch((err) => console.log(err));

      await Api.getAllParties()
        .then((res) => {
          setPartyOptions(res.data.data);
        })
        .catch((err) => console.log(err));

      await Api.getAllItems()
        .then((res) => {
          setItemOptions(res.data.data);
        })
        .catch((err) => console.log(err));

      if (id) {
        transaction_type === "orders"
          ? await Api.getOrderDetails(id)
              .then((res) => {
                // setFieldValues(temp)
                let { OrderItemLists, ...responseData } = res?.data?.data;
                let tempItems = [];
                setFieldValues({
                  party: responseData.Party,
                  note: responseData.note,
                });
                tempItems = structuredClone([...itemArray, ...OrderItemLists]);

                let n = tempItems?.length;

                setErrors(generateNewErrorObject(n));

                setItemArray(tempItems);
                setProductDetails({
                  ...responseData,
                  itemList: OrderItemLists,
                });
              })
              .catch((err) => {
                console.log("🚀 || err:", err);

                nav("/pagenotfound");
              })
          : await Api.getProductDetails(id)
              .then((res) => {
                let { ProductItemLists, ...responseData } = res?.data?.data;
                let tempItems = {};
                setFieldValues({
                  party: responseData.Party,
                  note: responseData.note,
                });
                tempItems = structuredClone([
                  ...itemArray,
                  ...ProductItemLists,
                ]);

                let n = tempItems.length;

                setErrors(generateNewErrorObject(n));
                setItemArray(tempItems);

                setProductDetails({
                  ...responseData,
                  itemList: ProductItemLists,
                });
              })
              .catch((err) => {
                console.log("🚀 || err:", err);

                nav("/pagenotfound");
              });
      }
      setLoading(false);
    })();
  }, [id]);

  const RenderRowItems = useMemo(() => {
    return (
      <RowItems
        itemOptions={itemOptions}
        handleInput={handleInput}
        itemArray={itemArray}
        errors={errors}
        setItemArray={setItemArray}
        setErrors={setErrors}
        transaction_type={transaction_type}
      />
    );
  }, [itemOptions, transaction_type, itemArray, errors.items]);

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="ProductsForm">
      <Paper elevation={2} className="paper" component="div">
        <div ref={scrollRef}>
          {alertMsg.msg && (
            <CustomAlert
              flag={alertMsg.flag}
              timeout={alertMsg.timeout}
              scrollRef={scrollRef}
              setAlertMsg={setAlertMsg}
            >
              {alertMsg.msg}
            </CustomAlert>
          )}
        </div>
        <PageHeader heading={transaction_type + " Form"} noLines={true} />
        <br />
        <Grid container spacing={5} className="form-grid">
          <Grid
            item
            xs={12}
            lg={12}
            md={12}
            className="d-flex justify-content-end pdf-option-grid"
          >
            <FormGroup className="pdf-option">

              <FormControlLabel
              
                control={
                  <Checkbox
                    checked={isPrintablePdf}
                    onChange={() => setIsPrintablePdf(!isPrintablePdf)}
                    inputProps={{ "aria-label": "controlled" }}
                    className="checkbox"
                  />
                }
                label="GENERATE PDF"
                labelPlacement="right"
              />
              <BsFillFilePdfFill className="pdf-icon" size={24} />
            </FormGroup>
            
          </Grid>
          <Grid item xs={12} lg={3} md={3.5}>
            <DateTimePickerWrapper
              dateTime={dateTime}
              changeDateTime={changeDate}
              errorText={errors.dateTime}
            />
          </Grid>
          <Grid item xs={12} lg={4} md={3.5}>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Party Name"
                  required
                  error={errors.party}
                  helperText={errors.party}
                  variant="standard"
                />
              )}
              options={partyOptions}
              getOptionLabel={(option) => option.partyName}
              clearOnEscape
              noOptionsText="No Parties Found"
              onChange={handlePartyChange}
              value={fieldValues.party}
            />
          </Grid>
          <Grid item container xs={12} sm={5} columnSpacing={5}>
            <Grid item xs={6} sm={6}>
              <TextField
                label="Created By"
                variant="standard"
                fullWidth
                value={
                  productDetails?.id
                    ? productDetails?.createdByUser.username
                    : userInfo?.username
                }
                disabled
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                label="Last Updated By"
                variant="standard"
                fullWidth
                value={
                  productDetails?.id
                    ? productDetails?.updatedByUser.username
                    : userInfo?.username
                }
                disabled
              />
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={4}
            className="item-grid"
          >
            {/* <RowItems
              itemOptions={itemOptions}
              handleItemChange={handleItemChange}
              handleInput={handleInput}
              itemArray={itemArray}
              errors={errors}
              setItemArray={setItemArray}
              setErrors={setErrors}
              transaction_type={transaction_type}
            /> */}
            {RenderRowItems}
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="note"
              name="note"
              label="Additional Notes"
              placeholder="Note any extra requirements or instructions"
              fullWidth
              variant="standard"
              onChange={handleInput}
              value={fieldValues.note}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            className="mt-5"
          >
            <Grid item>
              <Button
                type="submit"
                onClick={() => debounce(handleSubmit)}
                variant="contained"
                color="success"
                className="form-btn"
              >
                {buttonLoading ? (
                  <>
                    {" "}
                    {id ? "Update" : "Submit"} &nbsp;{" "}
                    <CircularProgress className="text-white" size={20} />
                  </>
                ) : !id ? (
                  <>
                    Submit &nbsp; <AiOutlineEnter size={20} />
                  </>
                ) : (
                  <>
                    Update &nbsp; <MdSystemUpdateAlt size={20} />
                  </>
                )}
              </Button>
            </Grid>
            <Grid item onClick={handleReset}>
              <Button variant="contained" color="error" className="form-btn">
                Reset &nbsp; <ImLoop2 size={17} style={{ color: "#fff" }} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default ProductsForm;
