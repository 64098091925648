import {axiosInstance} from './Common'
import 'axios'

export const getAllParties = () => {
    return axiosInstance.get('parties/all')
}

export const getAllItems = () => {
    return axiosInstance.get('items/all')
}

export const getProductDetails = (id) => {
    return axiosInstance.get('product-form/product/'+id)
}

export const getOrderDetails = (id) => {
    return axiosInstance.get('product-form/order/'+id)
}

export const addProduct = (data) => {
    return axiosInstance.post('product-form/product',data)
}

export const addOrder = (data) => {
    return axiosInstance.post('product-form/order',data)
}

export const updateProduct = (data) => {
    return axiosInstance.put('product-form/product',data)
}

export const updateOrder = (data) => {
    return axiosInstance.put('product-form/order',data)
}


