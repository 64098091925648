
import pdfMake from "pdfmake/build/pdfmake";

/**
 * 
 * @param {Object} orders 
 * @param {String} fileName 
 */
function generateOrderReport(orders,fileName){
    var __dirname = window.location.origin
    var fonts = {
        Roboto: {
            normal: __dirname + "/fonts/Roboto/Roboto-Regular.ttf",
            bold: __dirname + "/fonts/Roboto/Roboto-Medium.ttf",
            italics: __dirname + "/fonts/Roboto/Roboto-Italic.ttf",
            bolditalics: __dirname + "/fonts/Roboto/Roboto-MediumItalic.ttf",
          }
      };

    var pdfItems = []
    var totalFine = 0
    var orderNoList = []
    orders.forEach((order) => {
        let item = ''
        let weight = ''
        let touch = ''
        let fine = ''
        let dateTime = new Date(order.dateTime).toLocaleString()
        let note = order.note?order.note:" "
        orderNoList.push(order.id)
        order?.OrderItemLists.forEach((itemObj) => {
            totalFine += itemObj.fine
            item += itemObj.Item?.itemName + '\n'
            weight += itemObj.weight + '\n'
            touch += `${itemObj.lowTouch} - ${itemObj.touch}` + '\n'
            fine += itemObj.fine + '\n'
        })
        pdfItems.push([dateTime,order.id,{text:item},{text:weight,},touch,fine,{text:note,alignment:'left'}])

    })

    var docDefinition = {
        watermark: { text: 'RS Silver', color: 'grey', opacity: 0.2, bold: true, italics: false },
        content:[
                {
                    relativePosition: { x: 0, y: 3},
                    canvas:[
                        {
                            type: 'line',
                            x1:0, y1: 0,
                            x2:555, y2: 0,
                            lineWidth: 1,
                        },
                    ]
                },
                {text:'ORDER REPORT',style:'mainHeader',margin:[0,25,40,15]},
                {
                    relativePosition: { x: 0, y: 5},
                    canvas:[
                        {
                            type: 'line',
                            x1:0, y1: 0,
                            x2:555, y2: 0,
                            lineWidth: 1,
                        },
                    ],
                    
                },
                
                {
                    table:{
                        widths: [70,60,70,60,50,60,'*'],
                        dontBreakRows: true,
                        body:[
                            [
                                {text:`Order No: ${orderNoList}`,colSpan:7,alignment: 'center',margin:[0,4,0,4]},
                                {},{},{},{},{},{}
                            ],
                            [
                                {text:"Date, Time",style:'tableHeader'},
                                {text:"Order No.",style:'tableHeader'},
                                {text:"Item",style:'tableHeader'},
                                {text:"Qty (g)",style:'tableHeader'},
                                {text:"Touch",style:'tableHeader'},
                                {text:"Fine",style:'tableHeader'},
                                {text:"Notes",style:'tableHeader'},
                            ],
                            ...pdfItems
                        ]
                    },
                    alignment:'center',
                    margin:[0,20,40,0],
                },
    
                {
                    table:{
                        widths: ['*','*'],
                        body:[
                            [
                                {text:"Total Fine:",style:'columnHeader',alignment:'right'},
                                {text:totalFine,fontSize:14},
                            ],
                            ]
                    },
                    layout: 'noBorders',
                    margin:[0,25,0,0],
                    alignment:'left'
                },
            ],
            styles:{
                mainHeader:{
                    fontSize:15,
                    alignment:'center'
                },
                columnHeader:{
                    bold:true,
                    fontSize:14,
                },
                tableHeader:{
                    bold:true,
                    fontSize:13,
                    alignment:'center',
                    margin:[0,6,0,6]
                },
                multiRows:{
                    
                },
                line:{
                    margin:0
                }
                
            },
            pageSize:'A4',
            pageMargins:[20,20,-20,20]
    }
    
      
    var options = {
    // ...
    }
    
    pdfMake.createPdf(docDefinition,options,fonts).download(fileName);
}

export default generateOrderReport;



  
