import {axiosInstance} from './Common'

export const getPartyList = (params) => {
    return axiosInstance.get('parties',{params:{...params}})
}

export const addParty = (partyName) => {
    return axiosInstance.post('parties',{partyName:partyName})
}

export const updateParty = (data) => {
    return axiosInstance.put('parties',data)
}

export const deleteParty = (ids) =>{
    return axiosInstance.delete('parties',{data:ids})
}

export const getPartyDetails = (party_id) => {
    return axiosInstance.get('parties/'+party_id)
}

export const getAllSelectedParties = () => {
    return axiosInstance.get('parties/all-selected')
}