import {axiosInstance} from './Common'

export const getItemList = (params) => {
    return axiosInstance.get('items',{params:{...params}})
}

export const addItem = (itemName) => {
    return axiosInstance.post('items',{itemName:itemName})
}

export const updateItem = (data) => {
    return axiosInstance.put('items',data)
}

export const deleteItem = (ids) =>{
    return axiosInstance.delete('items',{data:ids})
}

export const getItemDetails = (item_id) => {
    return axiosInstance.get('items/'+item_id)
}

export const getAllSelectedItems = () => {
    return axiosInstance.get('items/all-selected')
}