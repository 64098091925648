import {axiosInstance} from './Common'

export const getBoughtProducts = (params) => {
    return axiosInstance.get('buy-products',{params})
}

export const getAllSelectedProductIds = () => {
    return axiosInstance.get('buy-products/all-selected')
}

export const deleteBoughtProducts = (ids) => {
    return axiosInstance.delete('buy-products',{data:ids})
}