import React, { useEffect, useState, useCallback } from "react";
import { AppBar } from "@mui/material";
import logo from "../../assets/images/RS Silver-logo.png";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "./Header.scss";
import "./HeaderResponsive.scss";
import { FiLogOut } from "react-icons/fi";
import { BiChevronDown, BiUserCircle } from "react-icons/bi";
import { logout as logoutApi } from "../../apis/User";

function Header() {
  const CustomNavlink = useCallback((props) => {
    return (
      <NavLink
        className={({ isActive }) =>
          (isActive ? "active" : "nav-link") + " " + props.className
        }
        end
        to={props.to}
      >
        {props.children}
      </NavLink>
    );
  }, []);

  const [auth, setAuth] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  var token = localStorage.getItem("token");

  const nav = useNavigate();
  const { pathname } = useLocation();

  const handleLogout = () => {
    logoutApi(token)
      .then(() => {
        localStorage.clear();
        return nav("/login", { replace: true });
      })
      .catch((err) => console.log("logout error", err));
  };

  useEffect(() => {
    if (token) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [token]);

  return (
    <AppBar position="static" className="appbar">
      <div className="d-flex justify-content-between">
        <div className="left">
          <div onClick={() => nav("/")} className="logo-wrapper">
            <img src={logo} alt="Logo"></img>
          </div>
          {auth === true && (
          <CustomNavlink className="left" to="/users">
            <h5>
              <BiUserCircle
                size={30}
                style={{ marginTop: "-2px", marginRight: 5 }}
              />{" "}
              {localStorage.getItem("username")}
            </h5>
          </CustomNavlink>
        )}
        </div>

        {auth === true ? (
          <>
            <div className="d-flex justify-content-start right nav-link-container">
              <CustomNavlink to="/" className='nav-item'>
                <h5>Home</h5>
              </CustomNavlink>

              <div className="products-form-dropdown nav-item" >
                <div className="d-flex align-items-center">
                  <h5
                    className={`${
                      [
                        "/products-form/orders",
                        "/products-form/inwards",
                        "/products-form/outwards",
                      ].includes(pathname)
                        ? "active"
                        : "nav-link"
                    }`}
                  >
                    Products Forms
                  </h5>
                  <BiChevronDown
                    size={28}
                    style={{ marginLeft: "-3px", marginTop: "1px" }}
                  />
                </div>
                <div className="nav-drop-down nav-item">
                  <CustomNavlink to="/products-form/orders">
                    Orders Form
                  </CustomNavlink>
                  <CustomNavlink to="/products-form/inwards">
                    Inwards Form
                  </CustomNavlink>
                  <CustomNavlink to="/products-form/outwards">
                    Outwards Form
                  </CustomNavlink>
                </div>
              </div>

              <div className="list-dropdown nav-item">
                <div className="d-flex align-items-center">
                  <h5
                    className={`${
                      ["/party-list", "/item-list"].includes(pathname)
                        ? "active"
                        : "nav-link"
                    }`}
                  >
                    Lists
                  </h5>
                  <BiChevronDown
                    size={28}
                    style={{ marginLeft: "-3px", marginTop: "1px" }}
                  />
                </div>
                <div className="nav-drop-down nav-item">
                  <CustomNavlink to="/party-list">Party List</CustomNavlink>
                  <CustomNavlink to="/item-list">Item List</CustomNavlink>
                </div>
              </div>

              <CustomNavlink to="/cash-account" className="nav-item">
                <h5>Cash Account</h5>
              </CustomNavlink>
              <CustomNavlink to="/orders" className="nav-item"> 
                <h5>Orders</h5>
              </CustomNavlink>
              <CustomNavlink to="/buy-products" className="nav-item">
                <h5>Inward Products</h5>
              </CustomNavlink>
              <CustomNavlink to="/sell-products" className="nav-item">
                <h5>Outward Products</h5>
              </CustomNavlink>

            </div>
            <div>
              <div onClick={handleLogout}>
                <div className="icon-wrapper-logout ">
                  <h5 className="logoutIcon">
                    Logout <FiLogOut size={20} />
                  </h5>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </AppBar>
  );
}

export default Header;
