import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';

import { keyframes } from '@emotion/react';
import { styled,lighten } from '@mui/material';

/**
 * @param {Number} timeLimit
 * @param {String} [color]
 * @returns {React.ReactElement}
 */

function TimedLinearProgress({timeLimit,color,counter}) {

    const indeterminate1Keyframes = keyframes({
        "0%": {
            left: "-35%",
            right: "100%"
        },
        "100%": {
            left: "0%",
            right: "0%"
        }
    });

    const StyledLinearProgress = styled(LinearProgress)({
        backgroundColor:lighten(color??'#1e90ff',0.7),
        "& .MuiLinearProgress-bar1Indeterminate": {
            width: "auto",
            animation: `${indeterminate1Keyframes} ${timeLimit??4000}ms linear forwards`,
            backgroundColor:color??'#1e90ff',
        },
        "& .MuiLinearProgress-bar2Indeterminate": {
            display: "none",
        }
        });

    return (
        <div style={{ width: "100%" }}>
            <StyledLinearProgress  variant="indeterminate" /> 
        </div>
    )
}

export default TimedLinearProgress