import {Navigate} from 'react-router-dom'


export const PrivateRoute = ({children}) => {
    let auth = localStorage.getItem('token')
    return auth?children:<Navigate to='/login' />
}
export const PublicRoute = ({children}) => {
    let auth = localStorage.getItem('token')
    return !auth?children:<Navigate to='/' />
}