import React,{ useState,useMemo} from 'react'
import {Button} from '@mui/material'
import ProductList from '../ProductList';
import PageHeader from '../../Common/PageHeader/PageHeader';
import ItemPopup from './ItemPopup';
import {AiOutlineUsergroupAdd,AiOutlineEdit} from 'react-icons/ai'
import * as Api from '../../../apis/ItemList';

import './ItemList.scss'
  
function ItemList(){
    
    const [openPopup,setOpenPopup] = useState(false)
    const [reloadItems,setReloadItems] = useState(false)
    const [editedItem,setEditedItem] = useState('')

    const handleEditItem = (params) => {

        setEditedItem({itemName:params.row.itemName,id:params.id})
        setOpenPopup(true)
    }

    const handleAddParty = () => {
        setEditedItem({itemName:'',id:''})
        setOpenPopup(true)
    }
    

    const columns = [
        {
            field: 'itemName',
            headerName: 'ITEM NAME',
            flex:2,
            sortingOrder:['asc','desc']
            
        },
        {
            field: 'edit',
            headerName: 'EDIT ITEM',
            sortable:false,
            flex:1,
            renderCell:(params) => (
                <div className="edit-button-col" onClick={((e) => e.stopPropagation())}>
                  <Button
                    variant="contained"
                    size="small"
                    className='edit-button'
                    tabIndex={-1}
                    onClick = {() => handleEditItem(params)}
                  >
                     EDIT  <AiOutlineEdit size={20} />
                  </Button>
                </div>
              )
        },
    ]

    const toggleReloadItems = () => {
        setReloadItems(!reloadItems)
    }

    const addButton = (
            <Button onClick={handleAddParty} variant='contained' color="info" >
                Add Item <AiOutlineUsergroupAdd size={23}  />
            </Button>
        )
    
    const ListRender = useMemo(() => {
        return(
            <ProductList 
              columns = {columns}
              getRowData = {Api.getItemList}
              getAllSelectedIds = {Api.getAllSelectedItems}
              addButton = {addButton}
              reloadRowData = {reloadItems}
              placeholder="Search by Item Name"
              initalField = 'itemName'
              initialSort = 'asc'
              deleteRowData = {Api.deleteItem}
            />
        )
    },[reloadItems])
    
    return(
        <div className='ItemList'>
            <ItemPopup open={openPopup} 
            setOpen={setOpenPopup} 
            addItemApi = {Api.addItem} 
            updateItemApi = {Api.updateItem} 
            reloadItems = {toggleReloadItems}
            editedItem={editedItem}
            />
            <PageHeader heading='Item list' />
            {ListRender}
      </div>
    )
}

export default ItemList