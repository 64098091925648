import React from 'react'
import './PageNotFound.scss'
import { Link } from 'react-router-dom'

function PageNotFound() {
  return (
    <div id='oopss'>
        <div id='error-text'>
            <span>404</span>
            <p>PAGE NOT FOUND</p>
            <p className='hmpg'><Link to='/'  className="back">Back To Home</Link></p>
        </div>
    </div>
  )
}

export default PageNotFound